import React, { useEffect, useRef, useState } from "react"
import down from "../../../assets/New/ChevronDown.svg"
import { Box, Modal } from "@mui/material"
import check from "../../../assets/New/Check.svg"
import defaultUser from '../../../assets/default-user.svg'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "#1A1C21",
  border: "none",
  boxShadow: 24,
  p: 6,
  borderRadius: "42px",
}

const UserRow = ({ d, deleteUser, changeRole }) => {
  const menuRef = useRef(null)
  const [showMenu, setShowMenu] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const [operationType, setOperationType] = useState()

  const tabs = [
    { title: "host" },
    { title: "photographer" },
    { title: "guest" },
  ]

  // handle confirm
  const confirm = () => {
    if (operationType === "Delete Member") {
      deleteUser(d?.id)
      return
    } else {
      changeRole(d?.id, operationType)
      return
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false)
      }
    }

    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  // single row Menu
  const Menu = () => (
    <div
      style={{
        background: "#1A1C21",
        width: "170px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "20px",
        position: "absolute",
        bottom: 0,
        transform: "translate(70%, 85%)",
        zIndex: "100",
      }}
    >
      {tabs?.map((t, idx) => (
        <span
          key={idx}
          onClick={() => {
            setOperationType(t.title)
            setModalOpen(true)
          }}
          style={{
            color: "white",
            fontFamily: "Inter",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "19.36px",
            cursor: "pointer",
            textTransform: "capitalize",
            display: "flex",
            gap: "12px",
            padding: "15.5px 16px",
          }}
          className={`${t.title === d?.role ? "logout-text-highlighted" : ""}`}
        >
          {t.title === d?.role && <img src={check} alt="" />}
          {t.title}
        </span>
      ))}
      <span
        // onClick={() => deleteUser(d?.id)}
        onClick={() => {
          setOperationType("Delete Member")
          setModalOpen(true)
        }}
        style={{
          padding: "15.5px 16px",
          color: "#FF5757",
          fontFamily: "Inter",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "19.36px",
          cursor: "pointer",
          borderTop: "1px solid #6F6F6F",
        }}
      >
        Remove Member
      </span>
    </div>
  )

  return (
    <>
      <div className="single-roledata-data" ref={menuRef}>
        <div className="image-data-box">
          <img
            className="roledata-img"
            src={d?.compressedProfilePhoto ? d?.compressedProfilePhoto : d?.profilePhoto ? d?.profilePhoto : defaultUser}
            alt="dataprofile"
          />
          <div className="mid-spacer-roledata">
            <p className="mid-spacer-title">
              {d?.firstName} {d?.lastName}
            </p>
            <p className="mid-spacer-role">{d?.role}</p>
          </div>
        </div>
        {/* <button onClick={() => deleteUser(d?.id)} className="roledata-remove-btn">
      Remove
    </button> */}

        {/* Quick Action */}
        <div
          className="roledata-remove-btn"
          onClick={() => setShowMenu(!showMenu)}
          style={{
            cursor: "pointer",
          }}
        >
          <span
            style={{
              textTransform: "capitalize",
              marginRight: "10px",
            }}
          >
            {d?.role}
          </span>
          <img src={down} alt="" />
          {showMenu && <Menu />}
        </div>
      </div>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex flex-column align-items-center">
            <p
              style={{
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: "28px",
                lineHeight: "33.89px",
              }}
              className="logout-text-highlighted"
            >
              {operationType === "Delete Member"
                ? "Delete Member"
                : "Grant Access"}
            </p>
            <div style={{ margin: "10px 0" }}>
              <span
                style={{
                  color: "#e1e1e1",
                  fontFamily: "Inter",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "29px",
                }}
              >
                Are you sure you want to{" "}
                {operationType === "Delete Member" ? (
                  "Remove Member"
                ) : (
                  <span>
                    give {d?.phoneNumber} “
                    <span
                      className="logout-text-highlighted"
                      style={{ textTransform: "capitalize" }}
                    >
                      {operationType} Access
                    </span>
                    ”?
                  </span>
                )}
              </span>
            </div>
            <div className="d-flex mt-4">
              <button
                type="button"
                className="btn me-4"
                style={{
                  border: "1px solid #FBCA7F",
                  color: "#FBCA7F",
                  borderRadius: "42px",
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "23.44px",
                  padding: "10px 50px",
                  cursor: "pointer",
                }}
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn"
                onClick={confirm}
                style={{
                  border: "1px solid #FBCA7F",
                  color: "#1A1C21",
                  borderRadius: "42px",
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "23.44px",
                  background:
                    "linear-gradient(180deg, #FBCA7F 0%, #997B3F 100%)",
                  padding: "10px 50px",
                  cursor: "pointer",
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default UserRow
