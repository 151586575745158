import React, { useState, useEffect, useRef } from 'react';
import "./CreateAlbumModal.scss";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import { createFolder, editFolder, getFoldersOfEvent, updateFolderCoverPhoto } from '../../../api/api';
import { apiURL } from '../../../api/apiConstants';
import Loader from "../../Loader/Loader";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import setCanvasPreview from '../../Home/Profile/canvasPreviewFn';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  // height: '80%',
  bgcolor: '#1A1C21',
  border: '2px solid #000',
  borderRadius: 10,
  boxShadow: 24,
  p: 4,
  color: '#fff',
  overflowY: 'scroll',
  // zIndex: '45455'
};

const deleteModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "45%",
  bgcolor: "#1A1C21",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "42px",
  zIndex: '454556'
}


const MIN_DIMENSION = 200

const CreateAlbumModal = ({ modalOpen, handleClose, handleCloseOption, albumTitle, name = "", openToPublic, folderId, eventId, coverPhoto,setFolderData }) => {
  const [albumName, setAlbumName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('private');
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

    const imgRef = useRef(null)
    const canvasRef = useRef(null)
    const [openCropModal, setOpenCropModal] = useState(false)
    const [crop, setCrop] = useState()

    function dataURLtoFile(dataURL) {
      const [header, base64Data] = dataURL.split(",")
      const mimeType = header.match(/:(.*?);/)[1]
  
      const binaryString = atob(base64Data)
      const byteNumbers = new Uint8Array(binaryString.length)
  
      for (let i = 0; i < binaryString.length; i++) {
        byteNumbers[i] = binaryString.charCodeAt(i)
      }
  
      const blob = new Blob([byteNumbers], { type: mimeType })
      const randomFileName = `${Math.random().toString(36).substring(2, 15)}.${
        mimeType.split("/")[1]
      }`
      return new File([blob], randomFileName, { type: mimeType })
    }

  const handleCrop = (file) => {
    const reader = new FileReader()

    reader.addEventListener("load", () => {
      const imgUrl = reader.result?.toString() || ""
      setFile(imgUrl)
      setOpenCropModal(true)
    })
    reader.readAsDataURL(file)
  }

  const loadCropper = (e) => {
      const { width, height } = e.currentTarget
  
      const cropWidthInPercent = (MIN_DIMENSION / width) * 100
  
      const crop = makeAspectCrop(
        { unit: "%", width: cropWidthInPercent },
        16/10,
        width,
        height
      )
  
      const centered = centerCrop(crop, width, height)
      setCrop(centered)
    }

  const saveCropped = () => {
    setCanvasPreview(
      imgRef.current,
      canvasRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    )

    const dataUrl = canvasRef.current.toDataURL()

    setFile(dataUrl)
    // handleChange(dataURLtoFile(dataUrl))
    setOpenCropModal(false)
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    handleCrop(droppedFile);
  };

  const handleBrowse = (e) => {
    const selectedFile = e.target.files[0];
    handleCrop(selectedFile);
  };


  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (openToPublic === false) {
      setSelectedValue('private');
    } else {
      setSelectedValue('public')
    }
    setAlbumName(name);
  }, [name, openToPublic]);

  const CallEdit = async () => {
    let openToPublic;
    setLoading(true);

    const storedKey = localStorage.getItem('key');
    const userId = JSON.parse(storedKey).body.id;
    const eventId = localStorage.getItem("eventId");

    // Set the value of openToPublic based on selectedValue
    openToPublic = (selectedValue === 'private') ? false : true;

    // Prepare the request body for editing the folder
    let requestBody = {
      "name": albumName,
      "id": folderId,
      "userId": userId,
      "eventId": eventId,
      "openToPublic": openToPublic
    };

    try {
      // Await the editFolder function (assuming it returns a Promise)
      const res = await editFolder(requestBody);

      // If there's no error and the file exists, update the cover photo
      if (res.error == null && file != null) {
        const formData = new FormData();
        formData.append('file', dataURLtoFile(file));

        // Await the updateFolderCoverPhoto function
        await updateFolderCoverPhoto(formData, folderId);
      }

      // Call the close functions after all async operations are done
      handleClose();
      handleCloseOption();

      // Reload the page after a small delay
      setTimeout(() => {
        window.location.reload(true);
      }, 4000);

    } catch (error) {
      console.error("Error occurred during the edit process:", error);
    }

    setLoading(false);
  };


  const callUpload = async () => {
    let openToPublic;
    setLoading(true);

    // Determine the value of openToPublic based on selectedValue
    openToPublic = (selectedValue === 'private') ? false : true;

    // Proceed if albumName is not empty
    if (albumName !== "") {
      let reqBody = {};
      const storedObjectString = localStorage.getItem('key');
      const storedObject = JSON.parse(storedObjectString);
      const storedEventId = localStorage.getItem('eventId');

      // Create the request body for creating the folder
      reqBody = {
        "name": albumName,
        "userId": storedObject.body.id,
        "eventId": storedEventId,
        "openToPublic": openToPublic
      };

      try {
        // Await the createFolder function (assuming it returns a Promise)
        const response = await createFolder(reqBody);

        // Check if the file and folder id exist
        if (file != null && response.body.id != null) {
          const formData = new FormData();
          formData.append('file', dataURLtoFile(file));

          // Await the updateFolderCoverPhoto function
          await updateFolderCoverPhoto(formData, response.body.id);
        }

        
        const storedId = localStorage.getItem("eventId");
        getFoldersOfEvent(storedId).then((response) => {
          if (!response.error) {
            setFolderData(response.body);
          }
          setLoading(false);
        });

        // Call the close functions
        handleClose();
        handleCloseOption();

        // // Reload the page after a small delay
        // setTimeout(() => {
        //   window.location.reload(true);
        // }, 4000);


      } catch (error) {
        console.error("Error occurred during the upload process:", error);
      }
    }

    setLoading(false);
  };



  return (
    <div className="box d-flex" style={{ zIndex: '454555' }}>

    {openCropModal ?  <Modal
        open={openCropModal}
        onClose={() => setOpenCropModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteModal}>
          <div className="d-flex flex-column">
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
              keepSelection
              aspect={16/10}
              minWidth={MIN_DIMENSION}
            >
              <img
                src={file}
                alt="upload"
                onLoad={loadCropper}
                ref={imgRef}
                style={{
                  maxHeight: '70vh'
                }}
              />
            </ReactCrop>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <button className="btn delete-modal-button" onClick={saveCropped}>
                Upload
              </button>
            </div>
          </div>
        </Box>
      </Modal>
:
      <Modal
        open={modalOpen}
        onClose={() => { handleClose(); handleCloseOption(); setFile(null); }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: '454555' }}
      >
        <Box sx={style}>
          <div className='d-flex justify-content-center'>
            <span className='title'>{albumTitle}&nbsp;Album</span>
          </div>
          <div className='d-flex flex-column align-items-start'>
            <span className='labels mt-4'>Name of the Album</span>
            <input
              className="mt-3 input-text"
              type="text"
              value={albumName}
              onChange={(event) => setAlbumName(event.target.value)}
            />

            <span className='labels mt-4'>Set Visibility to:</span>

            <div className='d-flex justify-content-between mt-3 w-100'>
              <div className='d-flex flex-column'>
                <span className='visibility-text'>Private</span>
                <span className='visibility-text-dec'>Only host and photographer have access to this album</span>
              </div>
              <Radio
                checked={selectedValue === 'private'}
                onChange={handleRadioChange}
                value="private"
                name="radio-buttons"
                sx={{
                  color: '#FBCA7F',
                  '&.Mui-checked': {
                    color: '#FBCA7F',
                  },
                }}
              />
            </div>

            <div className='d-flex justify-content-between mt-3 w-100'>
              <div className='d-flex flex-column'>
                <span className='visibility-text'>Public</span>
                <span className='visibility-text-dec'>Everyone can see this album</span>
              </div>
              <Radio
                checked={selectedValue === 'public'}
                onChange={handleRadioChange}
                value="public"
                name="radio-buttons"
                sx={{
                  color: '#FBCA7F',
                  '&.Mui-checked': {
                    color: '#FBCA7F',
                  },
                }}
              />
            </div>

            <div className='mt-4 w-100'>
              <span className='labels' >Upload Cover Image</span>
              <div className='mt-3'>
                <span className='drop-text'>Drag & Drop File Here or&nbsp;</span>
                <input
                  type="file"
                  accept='image/*'
                  onChange={handleBrowse}
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                />
                <span className='browse-button' onClick={handleBrowseClick}>
                  Browse
                </span>
                <div
                  className="d-flex align-items-center justify-content-center drop-area"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={handleDrop}
                >
                  {
                    file == null && albumTitle === 'Create' ? (
                      <>
                        <span className='drop-text'>Drag & Drop File Here</span>
                      </>
                    ) : (
                      <img src={
                        albumTitle === 'Edit' && file != null ? file
                          : albumTitle === 'Edit' && file == null ? coverPhoto
                            : albumTitle === 'Create' && file != null ? file
                              : null}
                        alt="Selected File" className='cover-img' />
                    )
                  }
                </div>
              </div>
            </div>

            <div className='mt-4 w-100 d-flex justify-content-around'>
              <div type="button" className={'cancel-upload me-3 d-flex justify-content-center align-items-center'}
                onClick={() => {
                  handleClose();
                  handleCloseOption();
                  setFile(null);
                }}>
                Cancel
              </div>
              <div type="button" className={'create-alb me-3 d-flex justify-content-center align-items-center'}
                onClick={() => {
                  if (albumTitle === 'Create') {
                    callUpload();
                  } else {
                    CallEdit();
                  }
                }}>
                {loading ? <Loader /> : albumTitle}
              </div>
            </div>
          </div>
        </Box>
      </Modal>}

      

      {crop && (
        <canvas
          ref={canvasRef}
          className="mt-4"
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: 150,
            height: 150,
          }}
        />
      )}
    </div>
  );
}

export default CreateAlbumModal;