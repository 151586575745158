import React from "react"

const FileCounter = ({ count }) => {
  return (
    <>
      <div
        style={{
          height: "33px",
          width: "1px",
          background: "white",
        }}
      />

      <div
        style={{
          color: "#706F6C",
          fontWeight: "600",
          fontFamily: "Inter",
          fontSize: "18px",
        }}
      >
        {count} Items
      </div>
    </>
  )
}

export default FileCounter
