import React, { useState, useEffect, useRef } from "react"
import "./Profile.scss"
import {
  getUserDetails,
  deleteAccount,
  uploadProfilePhoto,
} from "../../../api/api"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import { Close } from "@mui/icons-material"
import camera from "../../../assets/Icons/mdi_camera.svg"
import { toast, Toaster } from "sonner"
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop"
import setCanvasPreview from "./canvasPreviewFn"
import defaultUser from "../../../assets/default-user.svg"

const deleteModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "45%",
  bgcolor: "#1A1C21",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "42px",
}

const MIN_DIMENSION = 100

const Profile = () => {
  const ref = useRef(null)
  const imgRef = useRef(null)
  const canvasRef = useRef(null)
  const [userDetails, setUserDetails] = useState([])
  const [profileName, setProfileName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [fromTop, setFromTop] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [profilePhotoLoading, setProfilePhotoLoading] = useState(true)
  const [photoUrl, setPhotoUrl] = useState("")
  const [openCropModal, setOpenCropModal] = useState(false)
  const [crop, setCrop] = useState()

  // handle height from top
  useEffect(() => {
    const storageElement = document.getElementById("home-profile")
    if (storageElement) {
      setFromTop(storageElement.getBoundingClientRect().top)
    } else {
      console.error("Element with ID 'storage-page' not found.")
    }
  }, [])

  useEffect(() => {
    const storedKey = localStorage.getItem("key")
    const userId = JSON.parse(storedKey).body.id
    getUserDetails(userId).then((response) => {
      if (!response.error) {
        setUserDetails(response.body)
        const profileName =
          response.body?.firstName + " " + response.body?.lastName
        setProfileName(profileName)
        setPhotoUrl(
          response.body?.compressedProfilePhotoUrl
            ? response.body?.compressedProfilePhotoUrl
            : response.body?.profilePhotoUrl
            ? response.body?.profilePhotoUrl
            : defaultUser
        )
        setPhoneNumber(response.body?.phoneNumber)
      }
    })
  }, [])

  function dataURLtoFile(dataURL) {
    const [header, base64Data] = dataURL.split(",")
    const mimeType = header.match(/:(.*?);/)[1]

    const binaryString = atob(base64Data)
    const byteNumbers = new Uint8Array(binaryString.length)

    for (let i = 0; i < binaryString.length; i++) {
      byteNumbers[i] = binaryString.charCodeAt(i)
    }

    const blob = new Blob([byteNumbers], { type: mimeType })
    const randomFileName = `${Math.random().toString(36).substring(2, 15)}.${
      mimeType.split("/")[1]
    }`
    return new File([blob], randomFileName, { type: mimeType })
  }

  const handleCrop = (file) => {
    const reader = new FileReader()

    reader.addEventListener("load", () => {
      const imgUrl = reader.result?.toString() || ""
      setPhotoUrl(imgUrl)
      setOpenCropModal(true)
    })
    reader.readAsDataURL(file)
  }

  const loadCropper = (e) => {
    const { width, height } = e.currentTarget

    const cropWidthInPercent = (MIN_DIMENSION / width) * 100

    const crop = makeAspectCrop(
      { unit: "%", width: cropWidthInPercent },
      1,
      width,
      height
    )

    const centered = centerCrop(crop, width, height)
    setCrop(centered)
  }

  const saveCropped = () => {
    setCanvasPreview(
      imgRef.current,
      canvasRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    )

    const dataUrl = canvasRef.current.toDataURL()

    setPhotoUrl(dataUrl)
    handleChange(dataURLtoFile(dataUrl))
    setOpenCropModal(false)
  }

  const handleChange = async (file) => {
    setProfilePhotoLoading(true)
    const res = await uploadProfilePhoto(file, phoneNumber)

    setUserDetails({ ...userDetails, profilePhotoUrl: file })

    if (res.status === "OK") {
      toast.success("Profile Photo Uploaded Successfully")
    }

    setProfilePhotoLoading(false)
  }

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  const handleDeleteOpen = () => {
    setDeleteOpen(true)
  }
  const handleDeleteClose = () => {
    setDeleteOpen(false)
  }

  const handleImageLoad = () => {
    setProfilePhotoLoading(false)
  }

  const handleDelete = () => {
    const storedKey = localStorage.getItem("key")
    const userId = JSON.parse(storedKey).body.id
    deleteAccount(userId).then((res) => {
      if (!res.error) {
        localStorage.removeItem("key")
        localStorage.removeItem("eventId")
        window.location.reload(true)
      }
    })
  }

  return (
    <div
      className="d-flex flex-column align-items-center p-3 bg-black"
      style={{
        height: `calc(100vh - ${fromTop}px - 10px)`,
        borderRadius: "20px",
        margin: "10px",
      }}
      id="home-profile"
    >
      <Toaster />
      <Modal
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteModal}>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end">
              <Close className="action-button" onClick={handleDeleteClose} />
            </div>
            <div className="d-flex flex-column mt-2">
              <span className="delete-head">Delete Profile?</span>
              <span
                className="delete-text mt-2"
                style={{ textAlign: "center", fontSize: "18px" }}
              >
                Once you delete your account , you will lose all your data. Do
                you want to delete the Account?
              </span>
              <div
                className="d-flex"
                style={{ justifyContent: "center", marginTop: "2rem" }}
              >
                <button
                  type="button"
                  className="btn cancel-modal-button me-4"
                  onClick={handleDeleteClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn delete-modal-button"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <div className="d-flex flex-column align-items-center">
        <div style={{ position: "relative" }}>
          {profilePhotoLoading && <div className="shimmer" />}
          <img
            src={photoUrl}
            alt="profilePhoto"
            className="profile-photo"
            onLoad={handleImageLoad}
            style={{ display: profilePhotoLoading ? "none" : "block" }}
          />
          <img
            onClick={() => {
              if (ref.current) {
                ref.current.click()
              }
            }}
            src={camera}
            alt=""
            className="camera-edit"
            style={{ cursor: "pointer" }}
          />
          <input
            onChange={(e) => handleCrop(e?.target?.files[0])}
            ref={ref}
            type="file"
            style={{ display: "none" }}
          />
        </div>
        <span className="mt-3 profile-name">
          {userDetails.firstName && capitalize(userDetails.firstName)}
          &nbsp;
          {userDetails.lastName && capitalize(userDetails.lastName)}
        </span>
      </div>
      <div className="detials-holder d-flex flex-column">
        <span className="titles mt-4">Name</span>
        <input
          className="mt-2 input-text bg-transparent"
          type="text"
          readOnly
          value={profileName}
          onChange={(event) => setProfileName(event.target.value)}
        />

        <span className="titles mt-4">Phone Number</span>
        <input
          className="mt-2 input-text bg-transparent"
          type="text"
          readOnly
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event.target.value)}
        />
      </div>
      <div className="detials-holder d-flex flex-column">
        <span className="titles mt-4">Delete Account</span>
        <div className="d-flex justify-content-between align-items-center delete-box mt-2 p-3">
          <div className="d-flex flex-column">
            <span className="delete-title" style={{ fontSize: "20px" }}>
              Delete
            </span>
            <span className="delete-info" style={{ fontSize: "18px" }}>
              Once you delete your account , you will lose all your data
            </span>
          </div>
          <div>
            <button
              type="button"
              className="btn delete-button me-4"
              onClick={handleDeleteOpen}
            >
              Delete
            </button>
          </div>
        </div>
      </div>

      <Modal
        open={openCropModal}
        onClose={() => setOpenCropModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteModal}>
          <div className="d-flex flex-column">
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
              circularCrop
              keepSelection
              aspect={1}
              minWidth={MIN_DIMENSION}
            >
              <img
                src={photoUrl}
                alt="upload"
                onLoad={loadCropper}
                ref={imgRef}
                style={{
                  maxHeight: "70vh",
                }}
              />
            </ReactCrop>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <button className="btn delete-modal-button" onClick={saveCropped}>
                Upload
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      {crop && (
        <canvas
          ref={canvasRef}
          className="mt-4"
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: 150,
            height: 150,
          }}
        />
      )}
    </div>
  )
}

export default Profile
