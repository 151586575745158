import React from 'react';
import downArrow from "../../assets/Icons/downArrow.svg";
import xMark from "../../assets/Icons/X.svg";
import {useUpload} from "../../context/UploadContext";


const uploadProgressStyles = {
    position: "fixed",
    bottom: "10px",
    right: "10px",
    width: "350px",
    maxHeight: "300px",
    overflowY: "scroll",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    zIndex: 9999,
    border: "1px solid #ccc",
};

const uploadProgressHeaderStyles = {
    margin: 0,
    marginBottom: "10px",
    fontSize: "14px",
    textAlign: "center",
    paddingBottom: "5px",
};

const uploadProgressItemStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
    marginBottom: "5px",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
};

const fileNameStyles = {
    fontSize: "12px",
    fontWeight: "500",
    color: "#333",
};

const getStatusStyle = (status) => ({
    padding: "2px 6px",
    borderRadius: "12px",
    fontSize: "12px",
    color: "#fff",
});

export const UploadProgress = ({
                                   uploadingQueue,
                                   showUploadData,
                                   setShowUploadData
                               }) => {
    const { cancelUploads } = useUpload();
    if (uploadingQueue.length === 0) return null;

    return (
        <div style={{
            ...uploadProgressStyles,
            padding: showUploadData ? '10px' : '10px 10px 0 10px'
        }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: "0 10px",
                    borderBottom: showUploadData ? "1px solid #eee" : "none",
                    cursor: 'pointer'
                }}
            >
                <h4 style={uploadProgressHeaderStyles}>
                    Uploading {uploadingQueue.length} Files
                </h4>
                <div>
                    <img
                        style={{
                            transform: showUploadData ? 'translateY(-7px)' : 'translateY(+7px)',
                            rotate: !showUploadData ? "180deg" : "",
                            filter: 'brightness(0.7)',
                        }}
                        src={downArrow}
                        onClick={() => setShowUploadData(!showUploadData)}
                        alt=""
                    />
                    <img
                        style={{
                            transform: showUploadData ? 'translateY(-7px)' : 'translateY(-7px)',
                            filter: 'brightness(0.5)',
                            height: '16px',
                            width: '16px',
                            marginLeft: '12px'
                        }}
                        src={xMark}
                        onClick={() => cancelUploads()}
                        alt=""
                    />
                </div>
            </div>
            <div style={{height: showUploadData ? "" : "0"}}>
                {uploadingQueue?.map((uploadingItem, index) => (
                    <div key={index} style={uploadProgressItemStyles}>
                        <span style={fileNameStyles}>{uploadingItem.file.name}</span>
                        <span style={getStatusStyle(uploadingItem.status)}>
                            {uploadingItem.status === "success"
                                ? "✔️"
                                : uploadingItem.status === "failed"
                                    ? "❌"
                                    : "⏳"}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};