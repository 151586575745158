import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createProfile, uploadProfilePhoto } from "../../api/api";
import "./CompleteProfile.scss";
import cameraIcon from "../../assets/Icons/camera.svg";
import defaultProfilePhoto from "../../assets/Icons/profile.svg";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";
import setCanvasPreview from "../Home/Profile/canvasPreviewFn";
import { Box, Modal } from "@mui/material";

const deleteModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "45%",
  bgcolor: "#1A1C21",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "42px",
}

const MIN_DIMENSION = 100

const CompleteProfile = ({ setIsLoggedIn, setIsOtp }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const imgRef = useRef(null)
  const canvasRef = useRef(null)

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [errorMessage, setErrorMessage] = useState(""); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [photoUrl, setPhotoUrl] = useState("")
  const [openCropModal, setOpenCropModal] = useState(false)
  const [crop, setCrop] = useState() 

  function dataURLtoFile(dataURL) {
    const [header, base64Data] = dataURL.split(",")
    const mimeType = header.match(/:(.*?);/)[1]

    const binaryString = atob(base64Data)
    const byteNumbers = new Uint8Array(binaryString.length)

    for (let i = 0; i < binaryString.length; i++) {
      byteNumbers[i] = binaryString.charCodeAt(i)
    }

    const blob = new Blob([byteNumbers], { type: mimeType })
    const randomFileName = `${Math.random().toString(36).substring(2, 15)}.${
      mimeType.split("/")[1]
    }`
    return new File([blob], randomFileName, { type: mimeType })
  }

  const handleCrop = (file) => {
    const reader = new FileReader()

    reader.addEventListener("load", () => {
      const imgUrl = reader.result?.toString() || ""
      setSelectedPhoto(imgUrl)
      setOpenCropModal(true)
    })
    reader.readAsDataURL(file)
  }

  const loadCropper = (e) => {
    const { width, height } = e.currentTarget

    const cropWidthInPercent = (MIN_DIMENSION / width) * 100

    const crop = makeAspectCrop(
      { unit: "%", width: cropWidthInPercent },
      1,
      width,
      height
    )

    const centered = centerCrop(crop, width, height)
    setCrop(centered)
  }

  const saveCropped = () => {
    setCanvasPreview(
      imgRef.current,
      canvasRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    )

    const dataUrl = canvasRef.current.toDataURL()

    setSelectedPhoto(dataUrl)
    setPhotoUrl(dataUrl)
    setOpenCropModal(false)
  }

  useEffect(() => {
    const phone = location.state?.phoneNumber;
    if (phone) {
      setPhoneNumber(phone);
    } else {
      const details = JSON.parse(localStorage.getItem("key")) || {};
      if (details.status === "OK") {
        setPhoneNumber(details.body.phoneNumber);
      }
    }
  }, [location.state]);

  const handleSubmit = async () => {
    if (isSubmitting) return; 

    setIsSubmitting(true);
    setErrorMessage(""); 

    try {
      if (!firstName || !lastName || !phoneNumber) {
        setErrorMessage("Please fill in all fields.");
        setIsSubmitting(false);
        return;
      }

      const profileResponse = await createProfile({ firstName, lastName, phoneNumber });
      if (!profileResponse || profileResponse.status === "ERROR") {
        setErrorMessage(profileResponse?.error || "Failed to create profile.");
        setIsSubmitting(false);
        return;
      }

      if (selectedPhoto) {
        const file = dataURLtoFile(selectedPhoto, "profile-photo.heic");
        const photoResponse = await uploadProfilePhoto(file, phoneNumber);

        if (!photoResponse || photoResponse.status === "ERROR") {
          setErrorMessage(photoResponse?.error || "Failed to upload profile photo.");
          setIsSubmitting(false);
          return;
        }
      }

      const response = JSON.stringify(profileResponse);
      localStorage.setItem("key", response);
      setIsLoggedIn(true);
      setIsOtp(false);
      navigate("/home");
    } catch (error) {
      setErrorMessage("An unexpected error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false); 
    }
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setSelectedPhoto(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  return (
    <div className="complete-profile-container">
      <div className="d-flex flex-column align-items-center p-3 bg-black" style={{ borderRadius: "20px", margin: '10px', width: "100%", height: "100%" }}>
        <div className="d-flex justify-content-center mt-4 text-white">
          <h1 className="title">Tell us about yourself</h1>
        </div>
        <p style={{ fontSize: "15px", color: "white"}}>These details will be displayed to the guests</p>
        <div className="d-flex flex-column align-items-center mt-4">
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <img
              src={selectedPhoto || defaultProfilePhoto}
              alt="Profile"
              className="profile-photo"
              style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            />
            <label htmlFor="file-upload" style={{ position: 'absolute', bottom: '0', right: '0', cursor: 'pointer' }}>
              <img
                src={cameraIcon}
                alt="Edit"
                className="camera-edit"
                style={{ width: '30px', height: '30px', borderRadius: '50%' }}
              />
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={(e) => handleCrop(e?.target?.files[0])}
              style={{ display: 'none' }}
            />
          </div>
        </div>

        <div className="details-holder d-flex flex-column">
          <span className="titles mt-4">First Name</span>
          <input
            className="mt-2 input-text bg-transparent border"
            type="text"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          />

          <span className="titles mt-4">Last Name</span>
          <input
            className="mt-2 input-text bg-transparent"
            type="text"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          />

          <span className="titles mt-4">Phone Number</span>
          <input
            className="mt-2 input-text bg-transparent"
            type="text"
            value={phoneNumber}
            readOnly 
          />
        </div>

        {errorMessage && (
          <div className="error-message mt-4" style={{ color: "red" }}>
            {errorMessage}
          </div>
        )}

        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={isSubmitting}
            style={{
              minWidth: "8rem",
              background: "linear-gradient(90deg, #F6C67C 0%, #A58447 100%)",
              borderRadius: "25px",
              color: "#000",
              outline: "none",
              border: "none",
              padding: "10px 53px",
              fontFamily: "Roboto",
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "21.09px",
              textAlign: "right",
              cursor: isSubmitting ? "not-allowed" : "pointer",
            }}
          >
            {isSubmitting ? "Submitting..." : "Save Changes"}
          </button>
        </div>
      </div>

      <Modal
        open={openCropModal}
        onClose={() => setOpenCropModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteModal}>
          <div className="d-flex flex-column">
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
              circularCrop
              keepSelection
              aspect={1}
              minWidth={MIN_DIMENSION}
            >
              <img
                src={selectedPhoto}
                alt="upload"
                onLoad={loadCropper}
                ref={imgRef}
                style={{
                  maxHeight: '70vh'
                }}
              />
            </ReactCrop>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <button className="btn delete-modal-button" onClick={saveCropped}>
                Upload
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      {crop && (
        <canvas
          ref={canvasRef}
          className="mt-4"
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: 150,
            height: 150,
          }}
        />
      )}

    </div>
  );
};

export default CompleteProfile;
