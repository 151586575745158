import React, { useEffect, useRef, useState } from "react";
import info from "../../../../assets/CreateEvent/ri_information-line.svg";
import { MobileDatePicker } from "@mui/x-date-pickers";
import edit from "../../../../assets/New/PencilAlt.svg";
// import { FileUploader } from "react-drag-drop-files"
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Loader/Loader";
import {
  deleteEvent,
  editEventDetails,
  uploadCoverPhoto,
  userRoleInEvent,
} from "../../../../api/api";
import {Box, Modal, Switch} from "@mui/material";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";
import setCanvasPreview from "../../../Home/Profile/canvasPreviewFn";

// const fileTypes = ["JPG", "PNG", "GIF", "JPEG"]

const deleteModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "45%",
  bgcolor: "#1A1C21",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "42px",
  zIndex: '454556'
}


const MIN_DIMENSION = 200

const Tab2 = ({ data, nextPage, nextPageData }) => {
  const inpRef = useRef(null);
  const navigate = useNavigate();
  const [pageData, setPageData] = useState({});
  const [imgUrl, setImgUrl] = useState(null);
  const [apiCallSave, setApiCallSave] = useState(false);
  const [type, setType] = useState();
  const [allowContinue, setAllowContinue] = useState(false);
  const [deleteApiCall, setDeleteApiCall] = useState(false);
  const [allowDelete, setAllowDelete] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);

  const imgRef = useRef(null)
  const canvasRef = useRef(null)
  const [openCropModal, setOpenCropModal] = useState(false)
  const [crop, setCrop] = useState()

  function dataURLtoFile(dataURL) {
    const [header, base64Data] = dataURL.split(",")
    const mimeType = header.match(/:(.*?);/)[1]

    const binaryString = atob(base64Data)
    const byteNumbers = new Uint8Array(binaryString.length)

    for (let i = 0; i < binaryString.length; i++) {
      byteNumbers[i] = binaryString.charCodeAt(i)
    }

    const blob = new Blob([byteNumbers], { type: mimeType })
    const randomFileName = `${Math.random().toString(36).substring(2, 15)}.${
      mimeType.split("/")[1]
    }`
    return new File([blob], randomFileName, { type: mimeType })
  }

  const handleCrop = (file) => {
    const reader = new FileReader()

    reader.addEventListener("load", () => {
      const imgUrl = reader.result?.toString() || ""
      setImgUrl(imgUrl)
      setOpenCropModal(true)
    })
    reader.readAsDataURL(file)
  }

  const loadCropper = (e) => {
    const { width, height } = e.currentTarget

    const cropWidthInPercent = (MIN_DIMENSION / width) * 100

    const crop = makeAspectCrop(
      { unit: "%", width: cropWidthInPercent },
      16/10,
      width,
      height
    )

    const centered = centerCrop(crop, width, height)
    setCrop(centered)
  }

  const saveCropped = () => {
    setCanvasPreview(
      imgRef.current,
      canvasRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    )

    const dataUrl = canvasRef.current.toDataURL()

    setImgUrl(dataUrl)
    setPageData({...pageData, file:dataURLtoFile(dataUrl)})
    setOpenCropModal(false)
  }

// Handle toggle changes
  const handleToggle = (e) => {
    const { id, checked } = e.target;
    setPageData({ ...pageData, [id]: checked });
  };
  // const extractDate = () => {
  //   const dateArray = String(data?.date).split("-")
  //   return dateArray
  // }

  const handleDelete = async () => {
    const userRole = await userRoleInEvent(
      JSON.parse(localStorage.getItem("key"))?.body?.id,
      localStorage.getItem("eventId")
    );
    if (userRole?.status !== "OK") return;
    if (userRole?.body?.role !== "host") {
      setDeleteApiCall(false);
      setAllowDelete(false);
      setDeleteErrorMessage(true);
      return;
    }
    setDeleteApiCall(true);
    const res = await deleteEvent(data?.id);
    if (res?.status === "OK") {
      setDeleteApiCall(false);
      navigate("/home/all?page=0&limit=10");
    } else {
      setDeleteApiCall(false);
    }
  };

  // const handleChange = (file) => {
  //   setPageData({ ...pageData, file });

  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     setImgUrl(reader.result);
  //   };
  //   reader.readAsDataURL(file);
  // };

  const handleImage = () => {
    if (inpRef.current) {
      inpRef.current.click();
    }
  };

  const handleInput = (e) => {
    if (e?.target?.id === "about") {
      if (e?.target?.value?.split(" ")?.length === 101) return;
    }
    setPageData({ ...pageData, [e.target.id]: e.target.value });
  };

  const addZeroToDate = (date) => {
    const [year, month, day] = date.split("-");

    const formattedMonth = month.length === 1 ? `0${month}` : month;
    const formattedDay = day.length === 1 ? `0${day}` : day;
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

    return formattedDate;
  };

  const handleSave = async () => {
    if (!allowContinue) return;
    setApiCallSave(true);

    const apiDataBody = {
      id: data?.id,
      name: pageData?.name,
      description: pageData?.description,
      date: dayjs(pageData?.date).format("YYYY-MM-DD"),
      facePrivacy: data?.facePrivacy,
      guestUpload: data?.guestUpload,
      hostId: data?.hostId,
      vendors: data?.vendors,
      downloadEnabled: pageData?.downloadEnabled,
      dataCollectionEnabled: pageData?.dataCollectionEnabled,
    };

    const res = await editEventDetails(apiDataBody);
    if (res.status === "OK") {
      console.log("Edit Event Successful")
      if (pageData?.file) {
        const formdata = new FormData();
        formdata.append("file", pageData?.file, pageData?.file?.name);
        console.log("Form append Successful")
        const coverApiRes = await uploadCoverPhoto(formdata, data?.id);
        if (coverApiRes?.status === "OK") {
          console.log("Update Cover Photo Successful")
          setTimeout(() => {
            setApiCallSave(false);
            navigate("/event");
          }, 5000)
        } else {
          console.log("Update Cover Photo Failed")
          setApiCallSave(false);
        }
      } else {
        setApiCallSave(false);
        navigate("/event");
      }

      // sessionStorage.setItem("eventUpdated", true)
    } else {
      setApiCallSave(false);
    }
  };

  const handleContinue = () => {
    nextPageData(pageData);
    nextPage();
  };

  useEffect(() => {
    const changeContinueBtn = () => {
      if (pageData?.name && pageData?.description && pageData?.date)
        setAllowContinue(true);
      else setAllowContinue(false);
    };
    changeContinueBtn();
  }, [pageData]);

  // clear delete message
  useEffect(() => {
    const clearDeleteMessage = () => {
      if (!deleteErrorMessage) return;
      setTimeout(() => {
        setDeleteErrorMessage(false);
      }, 3000);
    };
    clearDeleteMessage();
  }, [deleteErrorMessage]);

  useEffect(() => {
    const setDataForPage = () => {
      setPageData({
        name: data?.name,
        coverPhotoLink: data?.compressedCoverPhotoLink || data?.coverPhotoLink,
        date: data?.date,
        description: data?.description,
        dataCollectionEnabled: data?.dataCollectionEnabled,
        downloadEnabled: data?.downloadEnabled,
      });
      setImgUrl(data?.compressedCoverPhotoLink || data?.coverPhotoLink);
    };
    setDataForPage();
  }, [data]);

  useEffect(() => {
    const storedType = JSON.parse(localStorage.getItem("eventType"));
    setType(storedType);
  }, []);

  return (
    <div style={{ fontFamily: "Inter" }}>
      <div
        className="album-poster-data"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <input
          onChange={(e) => handleCrop(e?.target?.files[0])}
          ref={inpRef}
          type="file"
          style={{ display: "none" }}
        />
        <img style={{ width: "300px" }} src={imgUrl} alt="" />
        <button
          onClick={handleImage}
          style={{
            width: "fit-content",
            marginTop: "20px",
            outline: "none",
            border: "none",
            background: "linear-gradient(90deg, #F6C67C 0%, #A58447 100%)",
            borderRadius: "35px",
            fontFamily: "Roboto",
            fontSize: "20px",
            fontWeight: "500",
            color: "#08090B",
            lineHeight: "23.44px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "11.5px 24px",
          }}
        >
          <img src={edit} alt="" /> <span>Edit Cover Image</span>
        </button>
        {/* <p className="album-poster-data-name">{data?.name}</p>
        <p className="album-poster-data-date">
          Created on {extractDate()[2]}-{extractDate()[1]}-{extractDate()[0]}
        </p> */}
      </div>

      <div style={{ marginTop: "30px" }} id="settings-events">
        <div className="inputs-main">
          <div className="event-main">
            <label htmlFor="name" className="event-title">
              Name of the Event
            </label>
            <input
              type="text"
              id="name"
              value={pageData?.name || ""}
              onChange={handleInput}
              placeholder="Name of the event"
            />
          </div>

          <div className="about-main">
            <div className="abt">
              <label htmlFor="description" className="about-title">
                About Event
              </label>
              <img
                className="abt-img"
                title="Write description for your event "
                src={info}
                alt=""
              />
            </div>

            <textarea
              name="about-event"
              id="description"
              value={pageData.description || ""}
              onChange={handleInput}
              placeholder="Add description of the event"
              rows={7}
            ></textarea>
            <p className="char-limiter">
              {pageData?.description?.split(" ")?.length || 0}/100
            </p>
          </div>

          <div className="date-selection">
            <p className="event-date">Date of the Event</p>
            <div>
              <MobileDatePicker
                className="picker"
                id="date"
                value={
                  (pageData.date && dayjs(pageData.date, "YYYY-MM-DD")) || null
                }
                onChange={(val) =>
                  setPageData({
                    ...pageData,
                    date: dayjs(val).format("YYYY-MM-DD"),
                  })
                }
                label="MM/DD/YYYY"
              />
            </div>
          </div>
          {type === "WEBAPP_SUBSCRIPTION_EVENT" && (
              <div className="recommended-settings">
                <p className="settings-title">Event Settings</p>
                <div className="toggle-group">
                  <div className="toggle-item">
                    <p className="toggle-label">Enable user downloads</p>
                    <Switch
                        id="downloadEnabled"
                        checked={pageData.downloadEnabled}
                        onChange={handleToggle}
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "#fbca7f", // Change the checked thumb color
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                            backgroundColor: "#fbca7f", // Change the checked track color
                          },
                          "& .MuiSwitch-track": {
                            backgroundColor: "#fbca7f", // Change the default track color
                          },
                        }}
                    />
                  </div>
                  <div className="toggle-item">
                    <p className="toggle-label">Enable data collection</p>
                    <Switch
                        id="dataCollectionEnabled"
                        checked={pageData.dataCollectionEnabled}
                        onChange={handleToggle}
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "#fbca7f", // Change the checked thumb color
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                            backgroundColor: "#fbca7f", // Change the checked track color
                          },
                          "& .MuiSwitch-track": {
                            backgroundColor: "#fbca7f", // Change the default track color
                          },
                        }}
                    />
                  </div>
                </div>
              </div>
          )}

          {/* <div className="upload">
            <div
              style={{
                display: "flex",
                justifyContent: imgUrl?.file ? "center" : "start",
                gap: "40px",
                alignItems: "center",
                marginLeft: imgUrl?.file && "-350px",
              }}
            >
              <p className="uploadTitle">Upload Cover Image</p>
              {imgUrl?.file && (
                <button
                  style={{
                    height: "max-content",
                    outline: "none",
                    marginTop: "20px",
                    border: "none",
                    background: "transparent",
                    color: "red",
                  }}
                  onClick={() => setImgUrl({ file: null })}
                >
                  Remove
                </button>
              )}
            </div>

            {!imgUrl?.file ? (
              <div className="uploadFile">
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  hoverTitle="Drop Here"
                  classes="dnd"
                  children=<p>
                    Drag and drop or <span className="browse">Browse</span>
                  </p>
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #4d4f57",
                    borderRadius: "12px",
                  }}
                  className="dnd-img"
                  src={imgUrl?.file}
                  alt=""
                />
              </div>
            )}
          </div> */}
        </div>

        <div className="btns-box" style={{ marginTop: "60px" }}>
          <button
            style={{
              cursor: allowContinue ? "pointer" : "not-allowed",
              width: type === "WEBAPP_SUBSCRIPTION_EVENT" && "600px",
              background:
                type === "WEBAPP_SUBSCRIPTION_EVENT" &&
                "linear-gradient(180deg, #FBCA7F 0%, #997B3F 100%)",
              color: type === "WEBAPP_SUBSCRIPTION_EVENT" && "#08090B",
            }}
            onClick={handleSave}
            className={`${type !== "WEBAPP_SUBSCRIPTION_EVENT" ? "save" : ""}`}
          >
            {apiCallSave ? <Loader /> : "Save"}
          </button>
          {type !== "WEBAPP_SUBSCRIPTION_EVENT" && (
            <button
              style={{ cursor: allowContinue ? "pointer" : "not-allowed" }}
              onClick={handleContinue}
              className="continue"
            >
              Continue
            </button>
          )}
        </div>
        {type === "WEBAPP_SUBSCRIPTION_EVENT" && (
          <div className="recommended-settings">
            <p className="recommended-settings-title">Delete Event</p>
            <div className="recommended-settings-container">
              <div className="delete-box-event-settings">
                <div>
                  <p className="delete-text">Delete</p>
                  <p className="delete-desc">Permanently delete this event</p>
                </div>
                <button
                  onClick={() => {
                    setDeleteApiCall(true);
                    setAllowDelete(true);
                  }}
                >
                  {deleteApiCall ? <Loader /> : "Delete"}
                </button>
              </div>
              {deleteErrorMessage && (
                <p className="inviteMessage">
                  You don't have the access to delete this event.
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      {allowDelete && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: "50",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#0009",
          }}
        >
          <div
              style={{
                background: "#08090B",
                color: "#e1e1e1",
                zIndex: "500",
                textAlign: "center",
                padding: "48px",
                borderRadius: "17px",
                width: "35%",
              }}
          >
            <p
                style={{
                  color: "#e1e1e1",
                  fontFamily: "Inter",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "29px",
                  margin: "0 0 40px 0",
                }}
            >
              Are you sure you want to delete this event?
            </p>
            <div className="btns-box">
              <button
                  className="save"
                  onClick={() => {
                    setDeleteApiCall(false)
                    setAllowDelete(false)
                  }}
                  style={{
                    border: "1px solid #FBCA7F",
                    color: "#FBCA7F",
                    radius: "42px",
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "23.44px",
                    padding: "10px 50px",
                  }}
              >
                Cancel
              </button>
              <button
                  className="continue"
                  style={{
                    border: "1px solid #FBCA7F",
                    color: "#1A1C21",
                    radius: "10px",
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "23.44px",
                    background:
                        "linear-gradient(180deg, #FBCA7F 0%, #997B3F 100%)",
                    padding: "10px 50px",
                  }}
                  onClick={handleDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      <Modal
        open={openCropModal}
        onClose={() => setOpenCropModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteModal}>
          <div className="d-flex flex-column">
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
              keepSelection
              aspect={16/10}
              minWidth={MIN_DIMENSION}
            >
              <img
                src={imgUrl}
                alt="upload"
                onLoad={loadCropper}
                ref={imgRef}
                style={{
                  maxHeight: '70vh'
                }}
              />
            </ReactCrop>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <button className="btn delete-modal-button" onClick={saveCropped}>
                Upload
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      {crop && (
        <canvas
          ref={canvasRef}
          className="mt-4"
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: 150,
            height: 150,
          }}
        />
      )}
    </div>
  );
};

export default Tab2;
