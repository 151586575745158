import React, { useState, useEffect, useRef } from "react"
import "./About.scss"
import { getEventsAbout } from "../../../api/api"
import videoIcon from "../../../assets/New/videos.svg"
import photoIcon from "../../../assets/New/picutres.svg"
import storageIcon from "../../../assets/New/storageicon.svg"
import profileIcon from "../../../assets/New/profile.svg"
import instagramIcon from "../../../assets/New/instagram.svg"
import websiteIcon from "../../../assets/New/website.svg"
import { useNavigate } from "react-router-dom"
import Loader from "../../Loader/Loader"
import defaultUser from "../../../assets/default-user.svg"

const About = () => {
  const pageRef = useRef(null)
  const navigate = useNavigate()
  const [data, setData] = useState()
  const [fromTop, setFromTop] = useState(null)
  const [showEventDetails, setShowEventDetails] = useState(true)
  const [hasSetNoVal, setHasSetNoVal] = useState(false)
  const [isCoverPhotoLoading, setIsCoverPhotoLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [reloadKey, setReloadKey] = useState(0)

  const handleImageError = () => {
    if (!hasError) {
      setHasError(true)
      setTimeout(() => {
        setReloadKey(reloadKey + 1) // Trigger reload
        setHasError(false) // Reset error state
      }, 1000) // Retry after 3 seconds
    }
  }

  const handleImageLoad = () => {
    setIsCoverPhotoLoading(false)
  }

  function getMonthName(monthString) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]

    let monthIndex = parseInt(monthString, 10)
    monthIndex = monthIndex - 1
    if (monthIndex >= 0 && monthIndex <= 11) {
      return monthNames[monthIndex]
    } else {
      throw new Error("Invalid month string. Must be between '01' and '12'.")
    }
  }

  const returnDate = () => {
    if (!data) return
    const datesArray = data?.date?.split("-")
    const year = datesArray[0]
    const month = parseInt(datesArray[1], 10)
    const day = datesArray[2]

    return `${day} ${getMonthName(month)}, ${year}`
  }

  const updateMaxHeight_ABOUTEVENT = () => {
    if (pageRef.current) {
      const distanceFromTop = pageRef.current.getBoundingClientRect().top
      const screenHeight = window.innerHeight
      const maxHeight = screenHeight - distanceFromTop - 10
      pageRef.current.style.maxHeight = `${maxHeight}px`
    }
  }

  // handle max height
  useEffect(() => {
    updateMaxHeight_ABOUTEVENT()
    window.addEventListener("resize", updateMaxHeight_ABOUTEVENT)

    return () => {
      window.removeEventListener("resize", updateMaxHeight_ABOUTEVENT)
    }
  }, [])

  // get events about data
  useEffect(() => {
    const storedId = localStorage.getItem("eventId")
    localStorage.removeItem("folderId")
    if (storedId) {
      getEventsAbout(storedId).then((response) => {
        if (!response.error) {
          console.log("event data fetched")
          setData(response.body)
          localStorage.setItem(
            "eventType",
            JSON.stringify(response?.body?.type)
          )
          localStorage.setItem(
            "eventStorage",
            JSON.stringify(response?.body?.storageUsed)
          )
        }
      })
    }
  }, [])

  // handle height from top
  useEffect(() => {
    const storageElement = document.getElementById("event-about-event-route")
    if (storageElement) {
      setFromTop(storageElement.getBoundingClientRect().top)
    } else {
      console.error("Element with ID 'event-about-event-route' not found.")
    }
  }, [])

  // check for event details
  useEffect(() => {
    const checkForEventDetails = () => {
      if (!data) return
      const res = !data?.vendors?.some((obj) =>
        Object.keys(obj).some(
          (key) =>
            key !== "id" &&
            key !== "category" &&
            obj[key] !== null &&
            obj[key].trim() !== ""
        )
      )
      setShowEventDetails(res)
    }
    checkForEventDetails()
  }, [data])

  return (
    <>
      {data ? (
        <div
          ref={pageRef}
          id="event-about-event-route"
          className="bg-black table-box"
          style={{
            // minHeight: `calc(100vh - ${fromTop}px - 16px)`,
            display: "flex",
            overflow: "scroll",
            position: "relative",
            margin: "10px",
          }}
        >
          <div
            style={{
              // borderRight: "0.5px solid  #706F6C",
              width:
                data?.type === null && showEventDetails
                  ? "100%"
                  : data?.type !== "WEBAPP_SUBSCRIPTION_EVENT" && "70%",
              padding:
                data?.type === "WEBAPP_SUBSCRIPTION_EVENT"
                  ? "16px 100px"
                  : "16px",
              flex: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <img
                  src={
                    data?.hostCompressedProfilePhoto
                      ? data?.hostCompressedProfilePhoto
                      : data?.hostProfilePhoto
                      ? data?.hostProfilePhoto
                      : defaultUser
                  }
                  alt=""
                  style={{
                    height: "56px",
                    width: "56px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
                <div>
                  <p
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "24px",
                      lineHeight: "29.5px",
                      color: "#e1e1e1",
                    }}
                  >
                    {data?.hostFirstName} {data?.hostLastName}
                  </p>
                  <p
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "19.36px",
                      color: "#6f6f6f",
                      marginTop: "4px",
                    }}
                  >
                    Created on {returnDate()}
                  </p>
                </div>
              </div>
              <button
                onClick={() => navigate("/event/album-settings")}
                className="edit-event-about-event-button"
                style={{
                  borderRadius: "42px",
                  padding: "15.5px 40.5px",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "14px",
                  color: "#08090B",
                }}
              >
                Edit Event
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isCoverPhotoLoading && (
                <div
                  className="shimmer"
                  style={{
                    width: "820px",
                    marginTop: "30px",
                    borderRadius: "32px",
                    marginBottom: "25px",
                    height: "550px",
                    objectFit: "cover",
                  }}
                ></div>
              )}
              <img
                key={reloadKey}
                src={data?.compressedCoverPhotoLink || data?.coverPhotoLink}
                style={{
                  width: "820px",
                  marginTop: "30px",
                  borderRadius: "32px",
                  marginBottom: "25px",
                  height: "550px",
                  objectFit: "cover",
                  display: isCoverPhotoLoading ? "none" : "block",
                }}
                onError={handleImageError}
                onLoad={handleImageLoad}
                alt=""
              />
            </div>
            <p
              className="gradient-name-about-event"
              style={{
                textAlign: "center",
                fontSize: "32px",
              }}
            >
              {data?.name}
            </p>
            <p
              style={{
                marginTop: "25px",
                marginBottom: "16px",
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "33.91px",
                color: "#E1E1E1",
              }}
            >
              About Event
            </p>
            <p
              className="gradient-name-about-event"
              style={{
                fontFamily: "Kalam",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "28.69px",
                textDecoration: "italic",
                marginBottom: "8px",
              }}
            >
              {returnDate()}
            </p>
            <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "20px",
                lineHeight: "28.26px",
                marginBottom: "25px",
                color: "#706F6C",
              }}
            >
              {data?.description}
            </p>
            {data?.type !== "WEBAPP_SUBSCRIPTION_EVENT" && (
              <div>
                <p
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "700",
                    fontSize: "24px",
                    lineHeight: "33.91px",
                    marginBottom: "25px",
                    color: "#E1E1E1",
                  }}
                >
                  Total Storage used on this event
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "50px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "2px solid #26272C",
                      padding: "10.5px 16px",
                      borderRadius: "16px",
                      gap: "16px",
                      flex: 1,
                    }}
                  >
                    <img
                      style={{ height: "46.67px", width: "46.67px" }}
                      src={storageIcon}
                      alt=""
                    />
                    <div>
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "600",
                          fontSize: "20px",
                          lineHeight: "28.26px",
                          color: "#ffffff",
                        }}
                      >
                        Total
                      </p>
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "600",
                          fontSize: "18px",
                          lineHeight: "25.44px",
                          color: "#706F6C",
                        }}
                      >
                        {data?.storageBought}GB
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "2px solid #26272C",
                      padding: "10.5px 16px",
                      borderRadius: "16px",
                      gap: "16px",
                      flex: 1,
                    }}
                  >
                    <img
                      style={{ height: "42px", width: "42px" }}
                      src={photoIcon}
                      alt=""
                    />
                    <div>
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "600",
                          fontSize: "20px",
                          lineHeight: "28.26px",
                          color: "#ffffff",
                        }}
                      >
                        Photos
                      </p>
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "600",
                          fontSize: "18px",
                          lineHeight: "25.44px",
                          color: "#706F6C",
                        }}
                      >
                        {data?.numberOfPhotos}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "2px solid #26272C",
                      padding: "10.5px 16px",
                      borderRadius: "16px",
                      gap: "16px",
                      flex: 1,
                    }}
                  >
                    <img
                      style={{ height: "28px", width: "46.67px" }}
                      src={videoIcon}
                      alt=""
                    />
                    <div>
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "600",
                          fontSize: "20px",
                          lineHeight: "28.26px",
                          color: "#ffffff",
                        }}
                      >
                        Videos
                      </p>
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "600",
                          fontSize: "18px",
                          lineHeight: "25.44px",
                          color: "#706F6C",
                        }}
                      >
                        {data?.numberOfVideos}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {!showEventDetails && (
            <>
              {data?.type !== "WEBAPP_SUBSCRIPTION_EVENT" && (
                <div
                  style={{
                    background: "#706F6C",
                    width: "0.5px",
                    // height: "100%",
                    minHeight: `calc(100vh - ${fromTop}px)`,
                  }}
                />
              )}
              {data?.type !== "WEBAPP_SUBSCRIPTION_EVENT" && (
                <div style={{ padding: "30px" }}>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "25px",
                      // lineHeight: "42px",
                      color: "#e1e1e1",
                      letterSpacing: "0.3px",
                    }}
                  >
                    Event Details
                  </p>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "32px",
                    }}
                  >
                    {data?.vendors?.map((dv) => {
                      const { name, website, instagram, phoneNumber } = dv
                      if (
                        name !== "" ||
                        website !== "" ||
                        instagram !== "" ||
                        phoneNumber !== ""
                      )
                        return (
                          <div key={dv?.id}>
                            <p
                              style={{
                                fontFamily: "Inter",
                                fontWeight: "500",
                                fontSize: "18px",
                                // lineHeight: "30px",
                                color: "#e1e1e1",
                                letterSpacing: "0.3px",
                              }}
                            >
                              {dv?.category}
                            </p>
                            {(dv?.name || dv?.phoneNumber) && (
                              <div
                                style={{
                                  fontFamily: "Inter",
                                  fontWeight: "400",
                                  fontSize: "15px",
                                  // lineHeight: "24px",
                                  color: "#706F6C",
                                  letterSpacing: "0.3px",
                                  margin: "11px 0",
                                  display: "flex",
                                  // flexDirection: "column",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={profileIcon}
                                  alt=""
                                  style={{ height: "20px", width: "20px" }}
                                />
                                <div>
                                  <p>{dv?.name}</p>
                                  <p>
                                    {dv?.phoneNumber && `(${dv?.phoneNumber})`}
                                  </p>
                                </div>
                              </div>
                            )}
                            {dv?.website && (
                              <div
                                style={{
                                  fontFamily: "Inter",
                                  fontWeight: "400",
                                  fontSize: "15px",
                                  // lineHeight: "24px",
                                  color: "#FBCA7F",
                                  marginBottom: "8px",
                                  letterSpacing: "0.3px",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                                title={dv?.website}
                              >
                                <img
                                  src={websiteIcon}
                                  alt=""
                                  style={{ height: "20px", width: "20px" }}
                                />
                                <a
                                  href={
                                    String(dv?.website)?.startsWith("http")
                                      ? dv?.website
                                      : `https://${dv?.website}`
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    color: "#FBCA7F",
                                    textDecoration: "none",
                                  }}
                                >
                                  {dv?.website?.length > 25
                                    ? `${dv.website.substring(0, 25)}...`
                                    : dv?.website}
                                </a>
                              </div>
                            )}
                            {dv?.instagram && (
                              <div
                                style={{
                                  fontFamily: "Inter",
                                  fontWeight: "400",
                                  fontSize: "15px",
                                  // lineHeight: "24px",
                                  color: "#FBCA7F",
                                  letterSpacing: "0.3px",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                                title={dv?.instagram}
                              >
                                <img
                                  src={instagramIcon}
                                  alt=""
                                  style={{ height: "20px", width: "20px" }}
                                />
                                <a
                                  href={dv?.instagram}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    color: "#FBCA7F",
                                    textDecoration: "none",
                                  }}
                                >
                                  {dv?.instagram?.length > 25
                                    ? `${
                                        dv?.instagram
                                          ?.split(".com/")[1]
                                          .split("?")[0]
                                      }`
                                    : dv?.instagram}
                                </a>
                              </div>
                            )}
                          </div>
                        )
                      return null
                    })}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div
          ref={pageRef}
          id="event-about-event-route"
          className=" table-box"
          style={{
            minHeight: `calc(100vh - ${fromTop}px - 16px)`,
            display: "flex",
            overflow: "scroll",
            position: "relative",
            margin: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ scale: "3" }}>
            <Loader />
          </div>
        </div>
      )}
    </>
  )
}

export default About
