import React, { useState, useEffect, useRef } from "react"
import quick_action from "../../../assets/New/quick_action.svg"
import { useNavigate } from "react-router-dom"
import "./AllEvents.scss"
import {downloadEventDataApi, getEventGuests} from "../../../api/api";
import defaultUser from '../../../assets/default-user.svg'

const SingleEventRow = ({
  item,
  index,
  currentOpenDialogue,
  setCurrentOpenDialogue,
  fromTop
}) => {
  const navigate = useNavigate()
  const menuRef = useRef(null)
  const tabs = [
    { title: "Open", method: open },
    { title: "Add User", method: addUser },
    { title: "Edit Event", method: editEvent },
    { title: "Share", method: share },
    { title: "Delete Event", method: deleteEvent },
    { title: "Download Data", method: downloadEventData },
  ]
  const [showMenu, setShowMenu] = useState(false)

  function setDeets() {
    localStorage.setItem("eventId", item.id)
    localStorage.setItem("eventName", item?.name)
    localStorage.setItem("eventDeeplink", item?.deeplink)
  }

  function open() {
    setDeets()
    navigate("/event/about")
  }

  function addUser() {
    setDeets()
    navigate("/event/album-access")
  }

  function editEvent() {
    setDeets()
    sessionStorage.setItem("editThisEventForMe", true)
    navigate("/event/album-settings")
  }

  function share() {
    setDeets()
    sessionStorage.setItem("shareThisEventForMe", true)
    navigate("/event/about")
  }

  function deleteEvent() {
    setDeets()
    sessionStorage.setItem("deleteThisEventForMe", true)
    navigate("/event/album-settings")
  }

  function downloadEventData() {
      downloadEventDataApi(item.id)
          .then(() => {
              console.log("File download initiated successfully");
          })
          .catch((error) => {
              console.error("Error during file download:", error);
          });
  }

  function getMonthName(monthString) {
    const monthNames = [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ]

    let monthIndex = parseInt(monthString, 10)
    monthIndex = monthIndex - 1
    if (monthIndex >= 0 && monthIndex <= 11) {
      return monthNames[monthIndex]
    } else {
      throw new Error("Invalid month string. Must be between '01' and '12'.")
    }
  }

  const returnDate = (date) => {
    if (!date) return
    const datesArray = date?.split("-")
    if (!datesArray || datesArray?.length <= 0) return
    return `${datesArray[2]} ${getMonthName(datesArray[1])}, ${datesArray[0]}`
  }

  const directOpen = () => {
    if (currentOpenDialogue) {
      setShowMenu(false)
      setCurrentOpenDialogue(null)
      return
    } else open()
  }

  useEffect(() => {
    if (currentOpenDialogue !== index) {
      setShowMenu(false)
    }
  }, [currentOpenDialogue, index])

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false)
      }
    }

    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const Menu = () => (
    <div
      style={{
        background: "#1A1C21",
        width: "160px",
        display: "flex",
        flexDirection: "column",
        padding: "8px 0",
        borderRadius: "20px",
        position: "absolute",
        bottom: 0,
        transform: "translate(-45%, 90%)",
        zIndex: "100",
      }}
    >
      {tabs?.map((t, idx) => (
        <span
          key={idx}
          onClick={t.method}
          style={{
            padding: "15.5px 16px",
            color: "white",
            fontFamily: "Inter",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "19.36px",
            cursor: "pointer",
          }}
        >
          {t.title}
        </span>
      ))}
    </div>
  )

  return (
    <div
      id="menu"
      ref={menuRef}
      key={index}
      style={{
        display: "flex",
        cursor: "pointer",
        background: currentOpenDialogue === index ? "#1a1c21" : "black",
        height: `calc((100vh - ${fromTop}px - 130px)/10)`
      }}
    >
      <div
        onClick={directOpen}
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr auto",
          padding: "0px 21px",
          alignItems: "center",
          fontFamily: "Inter",
          fontSize: "16px",
          gap: "14px",
          fontWeight: "500",
          lineHeight: "19.36px",
          color: "#706F6C",
          flex: 1,
        }}
      >
        {/* Photo and event name */}
        <div
          style={{
            width: "max-content",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <img
            className="profile-icon"
            src={item?.hostCompressedProfilePhoto ? item?.hostCompressedProfilePhoto : item?.hostProfilePhoto ? item?.hostProfilePhoto : defaultUser}
            alt="Profile"
          />
          <span title={item?.name}>
            {item?.name && item?.name?.length > 25
              ? `${item?.name?.slice(0, 25)}...`
              : item?.name || "N/A"}
          </span>
        </div>

        {/* Mobile Number */}
        <span style={{ textAlign: "center" }}>
          {item.hostPhoneNumber || "N/A"}
        </span>

        {/* Expires On */}
        <span style={{ textAlign: "center" }}>
          {returnDate(item?.expiryDate) || "N/A"}
        </span>

        {/* Host Name */}
        <span style={{ textAlign: "center" }}>
          {item.hostFirstName || "N/A"}
        </span>

        {/* Storage Used */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {item?.type === "WEBAPP_SUBSCRIPTION_EVENT" ? (
            <span className="gold-text" style={{ textAlign: "center" }}>
              {item?.storageUsed?.toFixed(2)}GB
            </span>
          ) : (
            <>
              <span className="gold-text" style={{ textAlign: "center" }}>
                {item?.storageUsed?.toFixed(2)}GB/
              </span>
              <span>{item?.storageBought}GB</span>
            </>
          )}
        </div>

        {/* Event Type */}
        <span
          style={{
            textAlign: "center",
            width: "100px",
            marginRight: "10px",
          }}
        >
          {item.type === "WEBAPP_SUBSCRIPTION_EVENT"
            ? "Web"
            : item.type === "WEBAPP_INDEPENDENT_EVENT" || item.type === "MOBILE_INDEPENDENT_EVENT"
            ? "Mobile"
            : "N/A"}
        </span>
      </div>
      {/* Quick Action */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100px",
          position: "relative",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          setShowMenu(true)
          setCurrentOpenDialogue(index)
        }}
      >
        <img src={quick_action} alt="" />
        {showMenu && <Menu />}
      </div>
    </div>
  )
}

export default SingleEventRow
