import "./App.css"
import Login from "./components/Login/Login"
import Otp from "./components/Otp/Otp"
import Home from "./components/Home/Home"
import Event from "./components/Event/Event"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import React, { useState } from "react"
import Pagination from "./components/Pagination/Pagination"
import BuyStorage from "./components/Storage/BuyStorage"
import PopEventId from "./components/PopEventIdChecker/PopEventId"
import CompleteProfile from "./components/CompleteProfile/CompleteProfile"
import SmallSreen from "./components/SmallScreen/SmallSreen"
import { UploadProvider, useUpload } from './context/UploadContext';
import 'react-image-crop/dist/ReactCrop.css'

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    () => localStorage.getItem("key") !== null
  )

  const [isOtp, setIsOtp] = useState(() => localStorage.getItem("key") !== null)

  return (
    <>
      <div className="App smallScreen">
        <SmallSreen />
      </div>
      <div className="App largeScreen">
        <UploadProvider>
          <Router>
            <PopEventId />
            <Routes>
              <Route path="/" element={<Login setIsOtp={setIsOtp} />} />
              <Route
                path="/otp"
                element={
                  isOtp ? (
                    <Otp setIsOtp={setIsOtp} setIsLoggedIn={setIsLoggedIn} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/CompleteProfile"
                element={
                  !isLoggedIn ? (
                    <CompleteProfile
                      setIsLoggedIn={setIsLoggedIn}
                      setIsOtp={setIsOtp}
                    />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/home"
                element={isLoggedIn ? <Home /> : <Navigate to="/" />}
              >
                <Route
                  path="dashboard"
                  element={isLoggedIn ? <Home /> : <Navigate to="/" />}
                />
                <Route
                  path="all"
                  element={isLoggedIn ? <Home /> : <Navigate to="/" />}
                />
                <Route
                  path="managed"
                  element={isLoggedIn ? <Home /> : <Navigate to="/" />}
                />
                <Route
                  path="referred"
                  element={isLoggedIn ? <Home /> : <Navigate to="/" />}
                />
                <Route
                  path="newref"
                  element={isLoggedIn ? <Home /> : <Navigate to="/" />}
                />
                <Route
                  path="profile"
                  element={isLoggedIn ? <Home /> : <Navigate to="/" />}
                />
                <Route
                  path="privacy"
                  element={isLoggedIn ? <Home /> : <Navigate to="/" />}
                />
                <Route
                  path="contact"
                  element={isLoggedIn ? <Home /> : <Navigate to="/" />}
                />
                <Route
                  path="storage"
                  element={isLoggedIn ? <Home /> : <Navigate to="/" />}
                />
              </Route>
              <Route
                path="/create-event"
                element={isLoggedIn ? <Pagination /> : <Navigate to="/" />}
              />
              <Route
                path="/buy-storage"
                element={isLoggedIn ? <BuyStorage /> : <Navigate to="/" />}
              />
              <Route
                path="/event"
                element={isLoggedIn ? <Event /> : <Navigate to="/" />}
              >
                <Route
                  path="about"
                  element={isLoggedIn ? <Event /> : <Navigate to="/" />}
                />
                <Route
                  path="all"
                  element={isLoggedIn ? <Event /> : <Navigate to="/" />}
                />
                <Route
                  path="physical"
                  element={isLoggedIn ? <Event /> : <Navigate to="/" />}
                />
                <Route
                  path="folder"
                  element={isLoggedIn ? <Event /> : <Navigate to="/" />}
                />
                <Route
                  path="album-access"
                  element={isLoggedIn ? <Event /> : <Navigate to="/" />}
                />
                <Route
                  path="album-settings"
                  element={isLoggedIn ? <Event /> : <Navigate to="/" />}
                />
              </Route>
            </Routes>
          </Router>
        </UploadProvider>
      </div>
    </>
  )
}

export default App
