import React, { useEffect, useRef, useState } from "react"
import backbtn from "../../../../assets/CreateEvent/Vector.svg"
import "./EventDetails.scss"
import info from "../../../../assets/CreateEvent/ri_information-line.svg"
import { FileUploader } from "react-drag-drop-files"
import { MobileDatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop"
import setCanvasPreview from "../../../Home/Profile/canvasPreviewFn"
import { Box, Modal } from "@mui/material"

const fileTypes = ["JPG", "PNG", "GIF"]

const deleteModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "45%",
  bgcolor: "#1A1C21",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "42px",
  zIndex: '454556'
}

const MIN_DIMENSION = 200

const EventDetails = ({ prevBtn, nextBtn }) => {
  const [inputChange, setInputChange] = useState({})
  const [imgUrl, setImgUrl] = useState(null)
  const [allowContinue, setAllowContinue] = useState(false)

  const imgRef = useRef(null)
  const canvasRef = useRef(null)
  const [openCropModal, setOpenCropModal] = useState(false)
  const [crop, setCrop] = useState()

  function dataURLtoFile(dataURL) {
    const [header, base64Data] = dataURL.split(",")
    const mimeType = header.match(/:(.*?);/)[1]

    const binaryString = atob(base64Data)
    const byteNumbers = new Uint8Array(binaryString.length)

    for (let i = 0; i < binaryString.length; i++) {
      byteNumbers[i] = binaryString.charCodeAt(i)
    }

    const blob = new Blob([byteNumbers], { type: mimeType })
    const randomFileName = `${Math.random().toString(36).substring(2, 15)}.${
      mimeType.split("/")[1]
    }`
    return new File([blob], randomFileName, { type: mimeType })
  }

  const handleCrop = (file) => {
    const reader = new FileReader()

    reader.addEventListener("load", () => {
      const imgUrl = reader.result?.toString() || ""
      setImgUrl({file: imgUrl})
      setOpenCropModal(true)
    })
    reader.readAsDataURL(file)
  }

  const loadCropper = (e) => {
    const { width, height } = e.currentTarget

    const cropWidthInPercent = (MIN_DIMENSION / width) * 100

    const crop = makeAspectCrop(
      { unit: "%", width: cropWidthInPercent },
      1,
      width,
      height
    )

    const centered = centerCrop(crop, width, height)
    setCrop(centered)
  }

  const saveCropped = () => {
    setCanvasPreview(
      imgRef.current,
      canvasRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    )

    const dataUrl = canvasRef.current.toDataURL()

    setImgUrl({...imgUrl, file:dataUrl})
    setInputChange({ ...inputChange, file:dataURLtoFile(dataUrl) })
    setOpenCropModal(false)
  }

  // const handleChange = (file) => {
  //   setInputChange({ ...inputChange, file })

  //   const reader = new FileReader()
  //   reader.onloadend = () => {
  //     setImgUrl({ file: reader.result })
  //   }
  //   reader.readAsDataURL(file)
  // }

  const handleInput = (e) => {
    if (e?.target?.id === "about") {
      if (e?.target?.value?.split(" ")?.length === 101) return
    }
    setInputChange({ ...inputChange, [e.target.id]: e.target.value })
  }

  const addZeroToDate = (date) => {
    const [year, month, day] = date.split("-")

    const formattedMonth = month.length === 1 ? `0${month}` : month
    const formattedDay = day.length === 1 ? `0${day}` : day
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`

    return formattedDate
  }

  const handleNext = async () => {
    if (!allowContinue) return
    const file = inputChange?.file

    const fileReader = new FileReader()

    fileReader.onloadend = () => {
      const base64File = fileReader.result

      const eventDetailsData = {
        name: inputChange?.eventName,
        description: inputChange?.about,
        date: addZeroToDate(inputChange?.date),
        file: base64File,
        fileName: inputChange?.file?.name,
      }

      sessionStorage.setItem(
        "MOBILE_EVENT_DETAILS",
        JSON.stringify(eventDetailsData)
      )

      nextBtn()
    }

    fileReader.readAsDataURL(file)
  }

  useEffect(() => {
    const setDataFromState = () => {
      const res = JSON.parse(sessionStorage.getItem("MOBILE_EVENT_DETAILS"))
      if (!res) return

      setInputChange({
        photographerName: res?.photographerName,
        eventName: res?.name,
        about: res?.description,
        date: res?.date,
        file: res?.file,
      })

      setImgUrl({ file: res?.file })
    }
    setDataFromState()
  }, [])

  useEffect(() => {
    const checkAndRemoveOverflowStyle = () => {
      const body = document.body
      if (body.style.overflow === "hidden") {
        body.style.overflow = ""
      }
    }

    checkAndRemoveOverflowStyle()
    window.addEventListener("resize", checkAndRemoveOverflowStyle)

    return () => {
      window.removeEventListener("resize", checkAndRemoveOverflowStyle)
    }
  }, [])

  useEffect(() => {
    const changeContinueBtn = () => {
      if (
        inputChange?.eventName &&
        inputChange?.about &&
        inputChange?.date &&
        inputChange?.file &&
        inputChange?.file !== null
      )
        setAllowContinue(true)
      else setAllowContinue(false)
    }
    changeContinueBtn()
  }, [inputChange])

  return (
    <div id="mobile-events">
      <div className="left-arrow" onClick={() => prevBtn()}>
        <img src={backbtn} alt="" />
      </div>

      <div className="head-main">
        <p className="heading">Fill your event details</p>
      </div>

      <div className="inputs-main">
        <div className="event-main">
          <label htmlFor="event-name" className="event-title">
            Name of the Event
          </label>
          <input
            type="text"
            id="eventName"
            value={inputChange?.eventName || ""}
            onChange={handleInput}
            placeholder="Name of the event"
          />
        </div>

        <div className="about-main">
          <div className="abt">
            <label htmlFor="about" className="about-title">
              About Event
            </label>
            {/* <img className="abt-img" title="something" src={info} alt="" /> */}
          </div>

          <textarea
            name="about-event"
            id="about"
            value={inputChange.about || ""}
            onChange={handleInput}
            placeholder="Add description of the event"
            rows={7}
          ></textarea>
          <p className="char-limiter">
            {inputChange?.about?.split(" ")?.length || 0}/100
          </p>
        </div>

        <div className="date-selection">
          <p className="event-date">Date of the Event</p>
          <div>
            <MobileDatePicker
              className="picker"
              id="date"
              value={inputChange.date ? dayjs(inputChange.date) : dayjs()}
              onChange={(val) =>
                setInputChange({
                  ...inputChange,
                  // date: val,
                  date: `${val?.$y}-${val?.$M + 1}-${val?.$D}`,
                })
              }
              label="MM/DD/YYYY"
            />
          </div>
        </div>

        <div className="upload">
          <div
            style={{
              display: "flex",
              justifyContent: inputChange?.file ? "center" : "start",
              gap: "40px",
              alignItems: "center",
              marginLeft: inputChange?.file && "-350px",
            }}
          >
            <p className="uploadTitle">Upload Cover Image</p>
            {inputChange?.file && (
              <button
                style={{
                  height: "max-content",
                  outline: "none",
                  marginTop: "20px",
                  border: "none",
                  background: "transparent",
                  color: "red",
                }}
                onClick={() =>
                  setInputChange((prev) => ({ ...prev, file: null }))
                }
              >
                Remove
              </button>
            )}
          </div>

          {!inputChange?.file ? (
            <div className="uploadFile">
              <FileUploader
                handleChange={handleCrop}
                name="file"
                types={fileTypes}
                hoverTitle="Drop Here"
                classes="dnd"
                children=<p>
                  Drag and drop or <span className="browse">Browse</span>
                </p>
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <img
                style={{
                  border: "1px solid #4d4f57",
                  borderRadius: "12px",
                }}
                className="dnd-img"
                src={imgUrl?.file}
                alt=""
              />
            </div>
          )}
        </div>
      </div>

      <div className="continue-btn-main">
        <p
          className="continue-btn"
          onClick={handleNext}
          style={{
            background: allowContinue
              ? "linear-gradient(90deg, #F6C67C 0%, #A58447 100%)"
              : "transparent",
            color: allowContinue ? "#000000" : "#fbca7f",
            border: !allowContinue && "1px solid #fbca7f",
            cursor: allowContinue ? "pointer" : "not-allowed",
          }}
        >
          Continue
        </p>
      </div>

      <Modal
        open={openCropModal}
        onClose={() => setOpenCropModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteModal}>
          <div className="d-flex flex-column">
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
              keepSelection
              aspect={16/10}
              minWidth={MIN_DIMENSION}
            >
              <img
                src={imgUrl?.file}
                alt="upload"
                onLoad={loadCropper}
                ref={imgRef}
                style={{
                  maxHeight: '70vh'
                }}
              />
            </ReactCrop>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <button className="btn delete-modal-button" onClick={saveCropped}>
                Upload
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      {crop && (
        <canvas
          ref={canvasRef}
          className="mt-4"
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: 150,
            height: 150,
          }}
        />
      )}

    </div>
  )
}

export default EventDetails
