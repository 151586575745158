import React, { useState, useEffect, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import "./AllEvents.scss"
import { getAllEvents } from "../../../api/api"
import Pagination from "@mui/material/Pagination"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import NoData from "../../NoData/NoData"
import SkeletonEvents from "../../SkeletonLoader/SkeletonEvents"
import SkeletonTableHeader from "../../SkeletonLoader/SkeletonTableHeader"
import SingleEventRow from "./SingleEventRow"

const theme = createTheme({
  palette: {
    primary: {
      main: "#26272C",
      contrastText: "#26272C",
    },
  },
})

const AllEvents = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const pageRef = useRef()
  const [fromTop, setFromTop] = useState(null)
  const [responseData, setResponseData] = useState([])
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [showNoData, setShowNoData] = useState(false)
  const [loading, setLoading] = useState(true)
  const [currentOpenDialogue, setCurrentOpenDialogue] = useState(null)
  const [changePageApi, setChangePageApi] = useState(false)

  useEffect(() => {
    const storedObjectString = localStorage?.getItem("key")
    const storedObject = JSON?.parse(storedObjectString)
    const queryParams = new URLSearchParams(location?.search)
    let requestBody = {
      id: storedObject?.body?.id,
    }

    for (const [key, value] of queryParams?.entries()) {
      requestBody[key] = value
    }

    getAllEvents(requestBody).then((response) => {
      if (!response?.error) {
        if (response?.events?.length === 0) {
          setShowNoData(true)
        }
        setResponseData(response?.events)
        setNumberOfPages(response?.numberOfPages)

        setTimeout(() => {
          setLoading(false)
        }, 1000)
      }
    })
  }, [location.search])

  const handleChange = (event, value) => {
    console.log(value, "val")

    setChangePageApi(true)
    navigate("/home/all?page=" + (value - 1) + "&limit=10")
    setTimeout(() => {
      setChangePageApi(false)
    }, 1000)
  }

  // handle max height of page
  const updateMaxHeight_ALLEVENTS = () => {
    if (pageRef.current) {
      const distanceFromTop = pageRef.current.getBoundingClientRect().top
      const screenHeight = window.innerHeight
      const maxHeight = screenHeight - distanceFromTop - 20
      pageRef.current.style.maxHeight = `${maxHeight}px`
    }
  }

  useEffect(() => {
    updateMaxHeight_ALLEVENTS()
    window.addEventListener("resize", updateMaxHeight_ALLEVENTS)

    return () => {
      window.removeEventListener("resize", updateMaxHeight_ALLEVENTS)
    }
  }, [])

  // handle height from top
  useEffect(() => {
    const storageElement = document?.getElementById("home-dashboard-all-events")
    if (storageElement) {
      setFromTop(storageElement?.getBoundingClientRect().top)
    } else {
      console?.error("Element with ID 'home-dashboard-all-events' not found.")
    }
  }, [])

  return (
    <div style={{ margin: "10px" }}>
      <div
        id="home-dashboard-all-events"
        className="table-box"
        ref={pageRef}
        style={{
          background: !loading && !changePageApi && "#000",
          minHeight: `calc(100vh - ${fromTop}px - 10px)`,
          borderRadius: "20px",
          overflowY: "scroll",
          position: "relative",
          // paddingBottom: "10px",
        }}
      >
        {loading && (
          <>
            <SkeletonTableHeader />
            <SkeletonEvents />
          </>
        )}
        {!loading && !changePageApi && !showNoData && (
          <div
            style={{
              background: "black",
              display: "grid",
              gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr auto auto",
              padding: "22px",
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "19.36px",
              color: "#ffffff",
              borderRadius: "20px 20px 0 0",
              position: "sticky",
              top: 0,
              gap: "14px",
              zIndex: "101",
              borderBottom: "0.5px solid #1c1d21",
            }}
          >
            {/* <span
              className="col-1 align-items-center d-flex"
              style={{ width: "65px" }}
            >
              Profile
              </span> */}
            <span>Event</span>
            <span style={{ textAlign: "center" }}>Mobile Number</span>
            {/* <span>Created On</span> */}
            <span style={{ textAlign: "center" }}>Expires On</span>
            <span style={{ textAlign: "center" }}>Host Name</span>
            <span style={{ textAlign: "center" }}>Storage Used</span>
            <span
              style={{
                textAlign: "center",
                width: "100px",
                marginRight: "10px",
              }}
              // style={{ width: "90px" }}
            >
              Event Type
            </span>
            <span style={{ textAlign: "center", width: "100px" }}>
              Quick Action
            </span>
          </div>
        )}

        {!loading && showNoData && <NoData />}

        {!loading ? (
          changePageApi ? (
            <>
              <SkeletonTableHeader />
              <SkeletonEvents />
              <SkeletonEvents />
              <SkeletonEvents />
            </>
          ) : (
            responseData?.map((item, index) => (
              <SingleEventRow
                item={item}
                index={index}
                key={index}
                currentOpenDialogue={currentOpenDialogue}
                setCurrentOpenDialogue={setCurrentOpenDialogue}
                fromTop={fromTop}
              />
            ))
          )
        ) : (
          <>
            <SkeletonTableHeader />
            <SkeletonEvents />
          </>
        )}

        <div
          style={{
            position: "absolute",
            bottom: 0,
            background: "black",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {!loading && (
            <ThemeProvider theme={theme}>
              <div
                className="events-tab-paginations-for-left-right"
                style={{ padding: "12px 0" }}
              >
                <Pagination
                  count={numberOfPages}
                  onChange={handleChange}
                  color="primary"
                  size="large"
                  boundaryCount={2}
                  siblingCount={2}
                />
              </div>
            </ThemeProvider>
          )}
        </div>
      </div>
    </div>
  )
}

export default AllEvents
