import React, { useEffect, useState } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import {
  deleteEvent,
  editUserRoleForEvent,
  getUserByPhoneNumber,
  toggleFaceRecognition,
  toggleGuestUpload,
  userRoleInEvent,
} from "../../../../api/api"
import { useNavigate } from "react-router-dom"
import Loader from "../../../Loader/Loader"
import { Box, Modal } from "@mui/material"
import defaultUser from '../../../../assets/default-user.svg'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "#1A1C21",
  border: "none",
  boxShadow: 24,
  p: 6,
  borderRadius: "42px",
}

const Tab1 = ({ data, nextPage }) => {
  const showRecommendedSettings =
    data?.hostId === JSON.parse(localStorage.getItem("key")).body?.id

  const navigate = useNavigate()
  const [toggleError, setToggleError] = useState(null)
  const [activeApiCall, setActiveApiCall] = useState(false)
  const [activeRole, setActiveRole] = useState("photographer")
  const [inviteUser, setInviteUser] = useState("")
  const [inviteMessage, setInviteMessage] = useState("")
  const [apiCall, setApiCall] = useState(false)
  const [showAccessTab, setShowAccessTab] = useState(false)
  const [deleteApiCall, setDeleteApiCall] = useState(false)
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setDeleteApiCall(false)
    setOpen(false)
  }

  const extractDate = () => {
    const dateArray = String(data?.date).split("-")
    return dateArray
  }

  const handleToggleFP = async () => {
    if (activeApiCall) return
    setActiveApiCall(true)
    const toggleElement = document.getElementById("toggle-circle-fp")

    const toggleParent = document.getElementById("toggle-handler-fp")

    const eventId = data?.id
    const userId = JSON.parse(localStorage.getItem("key"))?.body?.id

    if (toggleElement.classList.contains("toggle-left")) {
      // if (res?.status === "OK") {
      toggleElement.classList.remove("toggle-left")
      toggleElement.classList.add("toggle-right")
      toggleParent.classList.remove("toggle-off")
      toggleParent.classList.add("toggle-on")
      await toggleFaceRecognition(userId, eventId, true)
      // } else {
      //   setToggleError(res.error)
      // }
    } else if (toggleElement.classList.contains("toggle-right")) {
      // if (res?.status === "OK") {
      toggleElement.classList.remove("toggle-right")
      toggleElement.classList.add("toggle-left")
      toggleParent.classList.remove("toggle-on")
      toggleParent.classList.add("toggle-off")
      await toggleFaceRecognition(userId, eventId, false)
      // } else {
      //   setToggleError(res.error)
      // }
    }
    setActiveApiCall(false)
  }

  const handleToggleGU = async () => {
    if (activeApiCall) return
    setActiveApiCall(true)
    const toggleElement = document.getElementById("toggle-circle-gu")

    const toggleParent = document.getElementById("toggle-handler-gu")

    const eventId = data?.id
    const userId = JSON.parse(localStorage.getItem("key"))?.body?.id

    if (toggleElement.classList.contains("toggle-left")) {
      // if (res?.status === "OK") {
      toggleElement.classList.remove("toggle-left")
      toggleElement.classList.add("toggle-right")
      toggleParent.classList.remove("toggle-off")
      toggleParent.classList.add("toggle-on")
      await toggleGuestUpload(userId, eventId, true)
      // } else {
      //   setToggleError(res.error)
      // }
    } else if (toggleElement.classList.contains("toggle-right")) {
      // if (res?.status === "OK") {
      toggleElement.classList.remove("toggle-right")
      toggleElement.classList.add("toggle-left")
      toggleParent.classList.remove("toggle-on")
      toggleParent.classList.add("toggle-off")
      await toggleGuestUpload(userId, eventId, false)
      // } else {
      //   setToggleError(res.error)
      // }
    }
    setActiveApiCall(false)
  }

  const handleDelete = async () => {
    const userRole = await userRoleInEvent(
      JSON.parse(localStorage.getItem("key"))?.body?.id,
      localStorage.getItem("eventId")
    )
    if (userRole?.status !== "OK") return
    if (userRole?.body?.role !== "host") {
      setDeleteApiCall(false)
      handleOpen()
      setDeleteErrorMessage(true)
      return
    }
    setDeleteApiCall(true)
    const res = await deleteEvent(data?.id)
    if (res?.status === "OK") {
      setDeleteApiCall(false)
      navigate("/home/all?page=0&limit=10")
    } else {
      setDeleteApiCall(false)
    }
  }

  const addUser = async () => {
    setApiCall(true)
    setInviteUser("")
    const userRes = await getUserByPhoneNumber(`+${inviteUser}`)
    if (userRes?.status === "OK") {
      const roleChangeRes = await editUserRoleForEvent(
        userRes?.body?.id,
        localStorage?.getItem("eventId"),
        activeRole
      )
      if (roleChangeRes?.status === "OK") {
        setApiCall(false)
        setInviteMessage(roleChangeRes?.body)
      } else {
        setApiCall(false)
        setInviteMessage(roleChangeRes?.error)
      }
    } else {
      setApiCall(false)
      setInviteMessage(userRes?.error)
    }
  }

  // helper from menu
  useEffect(() => {
    const deleteHelper = () => {
      const helperType = JSON.parse(
        sessionStorage.getItem("deleteThisEventForMe")
      )
      if (helperType === true) {
        handleOpen()
        sessionStorage.removeItem("deleteThisEventForMe")
      }
    }
    deleteHelper()
  }, [])

  // clear invite message
  useEffect(() => {
    const clearInviteMessage = () => {
      if (inviteMessage === "") return
      setTimeout(() => {
        setInviteMessage("")
      }, 3000)
    }
    clearInviteMessage()
  }, [inviteMessage])

  // clear delete message
  useEffect(() => {
    const clearDeleteMessage = () => {
      if (!deleteErrorMessage) return
      setTimeout(() => {
        setDeleteErrorMessage(false)
      }, 3000)
    }
    clearDeleteMessage()
  }, [deleteErrorMessage])

  // removeToggleError
  useEffect(() => {
    const removeToggleError = () => {
      if (!toggleError) return
      setTimeout(() => {
        setToggleError(null)
      }, 2000)
    }
    removeToggleError()
  }, [toggleError])

  // setPrivacyToggle
  useEffect(() => {
    const setPrivacyToggle = () => {
      if (!data) return
      if (!showRecommendedSettings) return
      const toggleElement = document.getElementById("toggle-circle-fp")

      const toggleParent = document.getElementById("toggle-handler-fp")

      if (data?.facePrivacy === true) {
        toggleElement.classList.add("toggle-right")
        toggleParent.classList.add("toggle-on")
      } else {
        toggleElement.classList.add("toggle-left")
        toggleParent.classList.add("toggle-off")
      }
    }
    setPrivacyToggle()
  }, [data, showRecommendedSettings])

  // setGuestUploadsToggle
  useEffect(() => {
    const setPrivacyToggle = () => {
      if (!data) return
      if (!showRecommendedSettings) return
      const toggleElement = document.getElementById("toggle-circle-gu")

      const toggleParent = document.getElementById("toggle-handler-gu")

      if (data?.guestUpload === true) {
        toggleElement.classList.add("toggle-right")
        toggleParent.classList.add("toggle-on")
      } else {
        toggleParent.classList.add("toggle-off")
        toggleElement.classList.add("toggle-left")
      }
    }
    setPrivacyToggle()
  }, [data, showRecommendedSettings])

  return (
    <div style={{ position: "relative" }}>
      <Modal
        open={open}
        onClose={() => {
          handleClose()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <p
                style={{
                  color: "#e1e1e1",
                  fontFamily: "Inter",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "29px",
                  margin: "0 0 40px 0",
                }}
              >
                Are you sure you want to delete this event?
              </p>
              <div
                className="btns-box"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <button
                  className="save"
                  onClick={() => {
                    setDeleteApiCall(false)
                    handleClose()
                  }}
                  style={{
                    border: "1px solid #FBCA7F",
                    color: "#FBCA7F",
                    borderRadius: "42px",
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "23.44px",
                    padding: "10px 50px",
                    background: "transparent",
                  }}
                >
                  Cancel
                </button>
                <button
                  className="continue"
                  style={{
                    border: "1px solid #FBCA7F",
                    color: "#1A1C21",
                    borderRadius: "42px",
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "23.44px",
                    background:
                      "linear-gradient(180deg, #FBCA7F 0%, #997B3F 100%)",
                    padding: "10px 50px",
                  }}
                  onClick={handleDelete}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <div className="album-poster-data">
        <img style={{ width: "300px" }} src={data?.compressedCoverPhotoLink ? data?.compressedCoverPhotoLink : data?.coverPhotoLink ? data?.coverPhotoLink : defaultUser} alt="" />
        <p className="album-poster-data-name">{data?.name}</p>
        <p className="album-poster-data-date">
          Created on {extractDate()[2]}-{extractDate()[1]}-{extractDate()[0]}
        </p>
      </div>
      <div className="recommended-settings">
        <p className="recommended-settings-title">Edit Event Settings</p>
        <div
          onClick={nextPage}
          style={{ cursor: "pointer" }}
          className="recommended-settings-container"
        >
          <div className="access-box">
            <div className="content">
              <div>
                <p className="members-box-text">Edit Event</p>
                <p className="members-box-tags">
                  Edit cover image, name, other details
                </p>
              </div>
            </div>
            <div style={{ rotate: "-90deg" }}>
              <svg
                width="16"
                height="9"
                viewBox="0 0 16 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L8 8L15 1"
                  stroke="#E1E1E1"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {showRecommendedSettings && (
        <div className="recommended-settings">
          <p className="recommended-settings-title">Recommended Settings</p>
          <div className="recommended-settings-container">
            <div className="recommended-setting">
              <div className="content">
                <p className="content-title">Face Recognition Privacy</p>
                <p className="content-desc">
                  Guests can view only their photos by clicking a selfie. No
                  access to other photos
                </p>
              </div>
              <div
                id="toggle-handler-fp"
                className="toggle"
                onClick={handleToggleFP}
              >
                <div id="toggle-circle-fp" className="toggle-circle"></div>
              </div>
            </div>
            <div className="recommended-setting-divider" />
            <div className="recommended-setting">
              <div className="content">
                <p className="content-title">Guest uploads</p>
                <p className="content-desc">Allow guests to upload photos</p>
              </div>
              <div
                id="toggle-handler-gu"
                className="toggle"
                onClick={handleToggleGU}
              >
                <div id="toggle-circle-gu" className="toggle-circle"></div>
              </div>
            </div>
            <p className="toggle-error">{toggleError && toggleError}</p>
          </div>
        </div>
      )}
      <div className="recommended-settings">
        <p className="recommended-settings-title">Members</p>
        <div className="recommended-settings-container">
          <div className="members-box">
            <div className="members-img">
              <img src={data?.hostCompressedProfilePhoto ? data?.hostCompressedProfilePhoto : data?.hostProfilePhoto ? data?.hostProfilePhoto : defaultUser} alt="" />
              <div className="moreMems">{data?.numberOfUsers - 1}+</div>
            </div>
            <p style={{ fontSize: "20px" }}>{data?.numberOfUsers} Members</p>
          </div>
        </div>
      </div>
      <div className="recommended-settings">
        <p className="recommended-settings-title">Album Access</p>
        <div className="recommended-settings-container">
          <div
            style={{
              marginBottom: showAccessTab ? "40px" : "0px",
            }}
            onClick={() => setShowAccessTab(!showAccessTab)}
            className="access-box"
          >
            <div className="content">
              <img src={data?.hostCompressedProfilePhoto ? data?.hostCompressedProfilePhoto : data?.hostProfilePhoto ? data?.hostProfilePhoto : defaultUser} alt="" />
              <div>
                <p className="members-box-text">
                  {data?.hostFirstName} {data?.hostLastName} and{" "}
                  {data?.numberOfUsers - 1} Others
                </p>
                <p className="members-box-tags">Creator, Host</p>
              </div>
            </div>
            <div style={{ rotate: showAccessTab ? "0deg" : "-90deg" }}>
              <svg
                width="16"
                height="9"
                viewBox="0 0 16 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L8 8L15 1"
                  stroke="#E1E1E1"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div
            style={{
              height: showAccessTab ? "100%" : "0px",
              overflow: "hidden",
            }}
            className="rolebox"
          >
            <p className="rolebox-title">
              Select role and give access to members to manage this event
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                marginBottom: "15px",
                marginTop: "25px",
              }}
              onClick={() => setActiveRole("photographer")}
            >
              <p className="rolebox-rolename">Photographer</p>
              <div className="selected-role-container">
                {activeRole === "photographer" && (
                  <div className="active-role-indicator" />
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                marginBottom: "25px",
              }}
              onClick={() => setActiveRole("host")}
            >
              <p className="rolebox-rolename">Admin</p>
              <div className="selected-role-container">
                {activeRole !== "photographer" && (
                  <div className="active-role-indicator" />
                )}
              </div>
            </div>
            <div className="rolebox-input-box">
              <PhoneInput
                country={"in"}
                value={inviteUser}
                onChange={(e) => setInviteUser(e)}
                placeholder="Search by Contact Number"
              />

              <button
                style={{
                  padding: apiCall ? "0 70px" : "12px 40px",
                  width: apiCall ? "140px" : "",
                }}
                onClick={addUser}
              >
                {apiCall ? <Loader /> : "Submit"}
              </button>
            </div>
            <p className="inviteMessage">{inviteMessage}</p>
          </div>
        </div>
      </div>
      <div className="recommended-settings">
        <p className="recommended-settings-title">Storage Details</p>
        <div className="recommended-settings-container">
          <div className="storage-box">
            <p className="storage-used-text">Storage used by this event</p>
            <p className="storage-used-stat">
              {data?.storageUsed.toFixed(0)}GB/
              {data?.storageBought === null ? 0 : data?.storageBought}GB
            </p>
          </div>
        </div>
      </div>
      <div className="recommended-settings">
        <p className="recommended-settings-title">Delete Event</p>
        <div className="recommended-settings-container">
          <div className="delete-box-event-settings">
            <div>
              <p className="delete-text">Delete</p>
              <p className="delete-desc">Permanently delete this event</p>
            </div>
            <button
              onClick={() => {
                setDeleteApiCall(true)
                handleOpen()
              }}
            >
              {deleteApiCall ? <Loader /> : "Delete"}
            </button>
          </div>
          {deleteErrorMessage && (
            <p className="inviteMessage">
              You don't have the access to delete this event.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default Tab1
