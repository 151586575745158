import React, { useState, useEffect, useRef } from "react"
import "./Dashboard.scss"
import { useNavigate } from "react-router-dom"
import {
  getDashboard,
  deleteNotification,
  clearNotifications,
} from "../../../api/api"
import ActivityCalendar from "react-activity-calendar"
import { Circle, CurrencyRupee } from "@mui/icons-material"
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"
import { styled } from "@mui/material/styles"
import sparkle from "../../../assets/Icons/fluent_sparkle.svg"
import rupee from "../../../assets/Icons/rupees.svg"
import comingSoon from "../../../assets/New/event_overview_arriving_soon.png"
import deleteIcon from "../../../assets/delete.svg"
import defaultUser from "../../../assets/default-user.svg"
import noNotificationBell from "../../../assets/no-new-notif.svg"
import { toast } from "sonner"

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "dark" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#F6C67C" : "#A58447",
  },
}))

const datas = [
  { date: "2023-01-01", count: 16, level: 1 },
  { date: "2023-01-02", count: 2, level: 2 },
  { date: "2023-01-03", count: 3, level: 1 },
  { date: "2023-01-05", count: 5, level: 1 },
  { date: "2023-01-06", count: 9, level: 2 },
  { date: "2023-01-08", count: 10, level: 1 },
  { date: "2023-01-09", count: 7, level: 2 },
  { date: "2023-01-11", count: 8, level: 1 },
  { date: "2023-01-12", count: 15, level: 2 },
  { date: "2023-01-14", count: 6, level: 1 },
  { date: "2023-01-16", count: 4, level: 2 },
  { date: "2023-01-17", count: 13, level: 1 },
  { date: "2023-01-19", count: 5, level: 2 },
  { date: "2023-01-21", count: 8, level: 1 },
  { date: "2023-01-23", count: 9, level: 2 },
  { date: "2023-01-25", count: 7, level: 1 },
  { date: "2023-01-27", count: 10, level: 2 },
  { date: "2023-01-28", count: 6, level: 1 },
  { date: "2023-01-30", count: 11, level: 2 },
  { date: "2023-02-01", count: 12, level: 1 },
  { date: "2023-02-03", count: 4, level: 2 },
  { date: "2023-02-05", count: 8, level: 1 },
  { date: "2023-02-06", count: 14, level: 2 },
  { date: "2023-02-08", count: 7, level: 1 },
  { date: "2023-02-09", count: 3, level: 2 },
  { date: "2023-02-11", count: 10, level: 1 },
  { date: "2023-02-13", count: 5, level: 2 },
  { date: "2023-02-14", count: 11, level: 1 },
  { date: "2023-02-16", count: 6, level: 2 },
  { date: "2023-02-18", count: 9, level: 1 },
  { date: "2023-02-20", count: 15, level: 2 },
  { date: "2023-02-22", count: 4, level: 1 },
  { date: "2023-02-24", count: 8, level: 2 },
  { date: "2023-02-25", count: 13, level: 1 },
  { date: "2023-02-27", count: 7, level: 2 },
  { date: "2023-02-28", count: 10, level: 1 },
]

const labels = {
  months: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  weekdays: [
    "Sun", // Sunday first!
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ],
  totalCount: "{{count}} activities in {{year}}",
  legend: {
    less: "Less",
    more: "More",
  },
}

const theme = {
  dark: ["#AE50F8", "#F6C67C"],
}

const Dashboard = () => {
  const notifBoxRef = useRef(null)
  const navigate = useNavigate()
  const [userDetails, setUserDetails] = useState([])
  const [notification, setNotification] = useState([])
  const [demoEvent, setDemoEvent] = useState(true)
  const [notifications, setNotifications] = useState([])
  const [usedStorage, setUsedStorage] = useState(0)
  const [expiryDate, setExpiryDate] = useState()
  const [demoEventCoverPhotoLoading, setDemoEventCoverPhotoLoading] =
    useState(true)

  const updateMaxHeightNotif = () => {
    if (notifBoxRef.current) {
      const distanceFromTop = notifBoxRef.current.getBoundingClientRect().top
      const screenHeight = window.innerHeight
      const maxHeight = screenHeight - distanceFromTop - 10
      notifBoxRef.current.style.maxHeight = `${maxHeight}px`
    }
  }

  const handleImageLoad = () => {
    setDemoEventCoverPhotoLoading(false)
  }

  useEffect(() => {
    updateMaxHeightNotif()
    window.addEventListener("resize", updateMaxHeightNotif)

    return () => {
      window.removeEventListener("resize", updateMaxHeightNotif)
    }
  }, [])

  useEffect(() => {
    const storedKey = localStorage.getItem("key")
    const userId = JSON.parse(storedKey).body.id
    getDashboard(userId).then((response) => {
      if (!response.error) {
        setUserDetails(response.body)
        setNotification(response.body?.notifications)
        setUsedStorage(response.body.demoEventStorageUsed.toFixed(2))
        const date = new Date(response.body.subscriptionEndDate)
        const formattedDate = date.toISOString().split("T")[0]
        setExpiryDate(formattedDate)
      }
    })
  }, [])

  const navigateToFreeAlbum = () => {
    localStorage.setItem("eventId", userDetails?.demoEventId)
    localStorage.setItem("eventName", userDetails?.demoEventName)
    navigate("/event")
  }

  const handleDeleteNotification = async (notificationId) => {
    setNotification((prevNotification) =>
      prevNotification.filter(
        (notification) => notification.id !== notificationId
      )
    )
    await deleteNotification(notificationId)
  }

  const handleClearNotifications = async () => {
    const storedKey = localStorage.getItem("key")
    const userId = JSON.parse(storedKey).body.id
    const res = await clearNotifications(userId)
    if (res.status === "OK") {
      toast.success(res.body)
      setNotification([])
    }
  }

  return (
    <div
      id="dashboard"
      className="d-flex flex-column"
      style={{ padding: "10px" }}
    >
      <div className="d-flex">
        {!demoEvent ? (
          <div
            className="cards d-flex flex-column albums align-items-start"
            style={{
              marginRight: "10px",
              padding: "10px",
              justifyContent: "space-between",
            }}
          >
            <span
              className="free-album"
              title={userDetails?.demoEventName}
              style={{ fontSize: "28px" }}
            >
              {userDetails?.demoEventName}
            </span>
            <span className="portfolio-text mt-2">
              Create your portfolio album to showcase your work to clients.
            </span>
            <button
              type="button"
              className="btn free-album-button"
              style={{ padding: "7px 10px" }}
              onClick={() => {
                setDemoEvent(true)
              }}
            >
              Create your free album
            </button>
          </div>
        ) : (
          <div
            id="home-dashboard-first-box-p2"
            onClick={navigateToFreeAlbum}
            className="cards d-flex albums"
            style={{ cursor: "pointer", marginRight: "10px" }}
          >
            {demoEventCoverPhotoLoading && <div className="shimmer" />}
            <img
              src={
                userDetails.demoEventCompressedCoverPhotoLink
                  ? userDetails.demoEventCompressedCoverPhotoLink
                  : userDetails.demoEventCoverPhotoLink
                  ? userDetails.demoEventCoverPhotoLink
                  : defaultUser
              }
              onLoad={handleImageLoad}
              alt="eventcoverphoto"
              className="demo-photo me-1"
              style={{ display: demoEventCoverPhotoLoading ? "none" : "block" }}
            />
            <div
              style={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#26272C",
                  borderRadius: "8px",
                  padding: "5px 12px",
                  width: "130px",
                  height: "25px",
                  // marginBottom: "5px",
                  gap: "5px",
                }}
              >
                <img
                  src={sparkle}
                  style={{ height: "15px", width: "15px" }}
                  alt=""
                />
                <p
                  style={{
                    color: "white",
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    // textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  Your Portfolio
                </p>
              </div>
              <span
                className="free-album"
                style={{
                  fontWeight: "600",
                  fontSize: "28px",
                  textAlign: "start",
                }}
                title={userDetails?.demoEventName}
              >
                {/* serails.demoEvent */}
                {userDetails?.demoEventName?.length > 30
                  ? `${userDetails?.demoEventName?.slice(0, 30)}...`
                  : userDetails?.demoEventName}
              </span>
              <div>
                <BorderLinearProgress
                  variant="determinate"
                  className="mb-2"
                  value={
                    (userDetails.demoEventStorageUsed * 100) /
                    userDetails.demoEventStorageBought
                  }
                />
                <span className="reach-text">
                  {usedStorage}GB used out of&nbsp;
                  {userDetails.demoEventStorageBought}GB
                </span>
              </div>
            </div>
          </div>
        )}
        {/* // WORKING HERE */}
        <div
          id="home-dashboard-second-box"
          className="cards activity d-flex"
          style={{ justifyContent: "space-between", gap: "20px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <p className="activity-text" style={{ fontSize: "22px" }}>
              Your activity
            </p>
            <div
              // style={{ transform: "translateY(-15px)" }}
              className="d-flex justify-content-between align-items-center"
            >
              <div className="d-flex flex-column">
                <span
                  className="d-flex align-items-center event-text mb-2"
                  style={{ gap: "8px" }}
                >
                  <Circle className="event-dot" />
                  Total no. of events/clients
                </span>
                <span
                  className="d-flex align-items-center event-text"
                  style={{ gap: "8px" }}
                >
                  <Circle className="guest-dot" />
                  Total no. of new guests who have joined your event.
                </span>
              </div>
            </div>
            <p className="reach-text" style={{ fontSize: "20px" }}>
              You have reached&nbsp;{userDetails.numberOfPeopleReached}&nbsp;new
              people
            </p>
          </div>
          <div
            // style={{ transform: "translateX(10px)" }}
            className="d-flex"
            style={{ alignItems: "center" }}
          >
            <div className="d-flex" style={{ alignItems: "end" }}>
              <span
                className="d-flex align-items-center justify-content-center event-number"
                style={{ textAlign: "center" }}
              >
                <div>
                  {userDetails.numberOfEvents}
                  <p>Events</p>
                </div>
              </span>
              <span className="d-flex align-items-center justify-content-center guest-number">
                {userDetails.numberOfPeopleReached}
                <p>Guests</p>
              </span>
            </div>
          </div>
        </div>
        {/* // WORKING HERE */}
      </div>

      <div className="d-flex" style={{ marginTop: "10px" }}>
        <div
          className="d-flex flex-column info-left-card"
          style={{ marginRight: "10px" }}
        >
          {/* calender */}
          <div id="home-bashboard-third-box" className="cards calender-text">
            <img
              src={comingSoon}
              alt=""
              style={{ objectFit: "contain", height: "100%", width: "100%" }}
            />
            {/* <div>
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "29.05px",
                  color: "#e1e1e1",
                  marginBottom: "10px",
                }}
              >
                Event Overview
              </p>
            </div>
            <ActivityCalendar
              data={datas}
              blockMargin={4}
              blockSize={12}
              fontSize={14}
              labels={labels}
              theme={theme}
            /> */}
          </div>

          <div style={{ height: "100%", marginTop: "10px" }} className="d-flex">
            {/* money made */}
            <div
              id="home-dashboard-fifth-box"
              className="cards card-width d-flex flex-column align-items-center justify-content-center"
              style={{ padding: "10px", marginRight: "10px" }}
            >
              <span className="money-heading">
                Total Money Earned <br /> by Referrals
              </span>
              <img src={rupee} alt="" className="rupee" />
              <span className="money">{userDetails.totalMoneyEarned}</span>
            </div>
            {/* current storage */}
            <div
              id="home-dashboard-forth-box"
              className="cards card-width p-2 d-flex flex-column align-items-center justify-content-center"
            >
              <span className="money-heading">Current Storage Plan</span>
              <BorderLinearProgress
                variant="determinate"
                className=" mb-1 w-75"
                style={{ marginTop: "10px" }}
                value={
                  (userDetails.subscriptionStorageUsed * 100) /
                  userDetails.subscriptionStorageBought
                }
              />
              <span className="used-storage">
                {(userDetails.subscriptionStorageUsed ?? 0).toFixed(3)}&nbsp;GB
              </span>
              <span className="bought-storage mb-1">
                used out of&nbsp;{userDetails.subscriptionStorageBought}&nbsp;GB
              </span>
              <span className="expiration-date">
                Expires on &nbsp;{expiryDate}
              </span>
            </div>
          </div>
        </div>
        {/* notification */}
        <div
          id="home-dashboard-sixth-box"
          ref={notifBoxRef}
          className="d-flex flex-column cards info-right-card"
          style={{ gap: "0px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "0.5px solid #343a40",
              padding: "16px 0",
              position: "sticky",
              top: 0,
              background: "#000",
              zIndex: 1,
            }}
          >
            <span
              className="notification-heading"
              style={{ paddingLeft: "20px" }}
            >
              Notifications
            </span>
            {notification?.length > 0 && (
              <span
                onClick={handleClearNotifications}
                className="notification-clear-all"
                style={{ paddingRight: "20px" }}
              >
                Clear all
              </span>
            )}
          </div>
          <div className="scrollable-box" style={{ height: "100%" }}>
            {notification?.length > 0 ? (
              notification?.map((item, index) => (
                <div
                  style={{ padding: "15px 20px" }}
                  key={index}
                  className="d-flex flex-column single-notification-hover"
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between", gap: "15px" }}
                  >
                    <div className="notification-photo-content d-flex">
                      <div>
                        <img
                          src={
                            item.requesterCompressedProfilePhoto
                              ? item.requesterCompressedProfilePhoto
                              : item.requesterProfilePhoto
                          }
                          alt="requesterprofilephoto"
                          className="notification-photo"
                        />
                      </div>
                      <div>
                        <span
                          className="notification-name"
                          style={{ fontWeight: "bold" }}
                        >
                          {item.requesterName}
                        </span>
                        <p className="notification-invite">
                          has invited you to join an event.
                        </p>
                        <button
                          type="button"
                          onClick={() => {
                            localStorage.setItem("eventId", item.eventId)
                            navigate("/event/about")
                          }}
                          className="view-event-bttn"
                          style={{
                            marginTop: "12px",
                            borderRadius: "24px",
                            fontSize: "13px",
                            fontWeight: "600",
                            lineHeight: "14.52px",
                            letterSpacing: "0.2px",
                          }}
                        >
                          View Event
                        </button>
                      </div>
                    </div>
                    <img
                      src={deleteIcon}
                      alt="delete"
                      onClick={() => handleDeleteNotification(item.id)}
                      style={{
                        cursor: "pointer",
                        height: "20px",
                        width: "20px",
                        marginTop: "8px",
                      }}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{ height: "100%", gap: "18px" }}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <img
                  src={noNotificationBell}
                  alt=""
                  style={{ height: "60px", width: "60px" }}
                />
                <p
                  style={{
                    color: "#706F6C",
                    fontWeight: "500",
                    fontSize: "20px",
                    lineHeight: "23.44px",
                    textAlign: "center",
                  }}
                >
                  You have no new notifications
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
