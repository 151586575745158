import React, { createContext, useContext, useState, useCallback, useRef, useEffect } from 'react';
import { UploadProgress } from "../components/Upload/UploadProgress";
import { uploadFilesV2 } from "../api/api";

const UploadContext = createContext();

export const UploadProvider = ({ children }) => {
    const [uploadingQueue, setUploadingQueue] = useState([]);
    const [showUploadData, setShowUploadData] = useState(true);
    const isProcessing = useRef(false);
    const cancelRef = useRef(false);

    const cancelUploads = useCallback(async () => {
        try {
            cancelRef.current = true; // Signal cancellation
            isProcessing.current = false; // Stop processing
            setUploadingQueue([]); // Clear the queue
        } catch (error) {
            console.error("Error cancelling uploads:", error);
            return { success: false, queue: [] };
        }
    }, []);

    const addToUploadQueue = useCallback(async (files, selectedFolder) => {
        try {
            const storedObjectString = localStorage.getItem("key");
            const storedObject = JSON.parse(storedObjectString);
            const storedEventId = localStorage.getItem("eventId");

            const newFilesArray = Array.from(files);
            const newQueueItems = newFilesArray.map(file => ({
                folderId: selectedFolder?.id,
                file: file,
                status: "pending",
                userId: storedObject.body.id,
                eventId: storedEventId,
                timestamp: Date.now(),
                id: Math.random().toString(36).substr(2, 9)
            }));
            cancelRef.current = false;
            return new Promise(resolve => {
                setUploadingQueue(prevQueue => {
                    const updatedQueue = [...prevQueue, ...newQueueItems];
                    resolve({ success: true, queue: updatedQueue });
                    return updatedQueue;
                });
            });
        } catch (error) {
            return { success: false, queue: [] };
        }
    }, []);

    const processNextItem = useCallback(async () => {
        if (isProcessing.current || cancelRef.current) {
            return; // Don't process if already processing or cancelled
        }
        const pendingItem = uploadingQueue.find(item => item.status === "pending");
        if (!pendingItem) {
            isProcessing.current = false;
            return; // No pending items to process
        }

        isProcessing.current = true;

        try {
            const res = await uploadFilesV2(pendingItem);
            setUploadingQueue(prevQueue => {
                return prevQueue.map(queueItem => {
                    if (queueItem.id === pendingItem.id) {
                        return {
                            ...queueItem,
                            status: res?.status === "OK" ? "success" : "failed"
                        };
                    }
                    return queueItem;
                });
            });

            if (res?.status === "OK") {
                setTimeout(() => {
                    setUploadingQueue(prevQueue =>
                        prevQueue.filter(queueItem => queueItem.id !== pendingItem.id)
                    );
                }, 3000);
            }
        } catch (error) {
            setUploadingQueue(prevQueue => {
                return prevQueue.map(queueItem => {
                    if (queueItem.id === pendingItem.id) {
                        return {
                            ...queueItem,
                            status: "failed"
                        };
                    }
                    return queueItem;
                });
            });
        } finally {
            isProcessing.current = false;
        }
    }, [uploadingQueue]);

    // Watch queue for pending items and start processing if needed
    useEffect(() => {
        if (uploadingQueue.some(item => item.status === "pending") && !isProcessing.current && !cancelRef.current) {
            processNextItem();
        }
    }, [uploadingQueue]);

    const value = {
        uploadingQueue,
        showUploadData,
        setShowUploadData,
        addToUploadQueue,
        cancelUploads
    };

    return (
        <UploadContext.Provider value={value}>
            {children}
            <UploadProgress
                uploadingQueue={uploadingQueue}
                showUploadData={showUploadData}
                setShowUploadData={setShowUploadData}
            />
        </UploadContext.Provider>
    );
};

export const useUpload = () => {
    const context = useContext(UploadContext);
    if (!context) {
        throw new Error('useUpload must be used within an UploadProvider');
    }
    return context;
};