import React, { useState, useEffect, useRef } from "react"
import "./PhysicalAlbum.scss"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import {
  getPhysicalAlbum,
  moveToPhysicalAlbum,
  removeFromPhysicalAlbum,
  uploadFiles,
} from "../../../api/api"
import Checkbox from "@mui/material/Checkbox"
import { Download } from "@mui/icons-material"
import JSZip from "jszip"
import download from "../../../assets/Icons/download.svg"
import deleteAlbum from "../../../assets/Icons/delete_album.svg"
import MoveFolder from "../../../assets/Icons/move_folder.svg"
import CancelIcon from "@mui/icons-material/Cancel"
import downloadActive from "../../../assets/Icons/download_active.svg"
import deleteAlbumActive from "../../../assets/Icons/delete_album_active.svg"
import folderMoveActive from "../../../assets/Icons/move_folder_active.svg"
import { useNavigate } from "react-router-dom"
import { IconButton, Tooltip } from "@mui/material"
import albumView from "../../../assets/Icons/album.svg"
import { toast } from "sonner"
import FileCounter from "../FileCounter"

const PhysicalAlbum = () => {
  const navigate = useNavigate()
  const [responseData, setResponseData] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedItems, setSelectedItems] = useState([])
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [fromTop, setFromTop] = useState(null)
  const [uploadMessage, setUploadMessage] = useState("")
  const [selectedImageIds, setSelectedImageIds] = useState([])
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null)
  const [isShiftPressed, setIsShiftPressed] = useState(false)
  const [lastShiftSelectedIndex, setLastShiftSelectedIndex] = useState(null)
  const [noFiles, setNoFiles] = useState(false)

  const containerRef = useRef(null)
  const fileInputRef = useRef(null)

  useEffect(() => {
    setNoFiles(false)
    const storedId = localStorage.getItem("eventId")
    const storedKey = localStorage.getItem("key")
    const userId = JSON.parse(storedKey).body.id
    localStorage.removeItem("folderId")
    if (storedId && currentPage === 1) {
      setLoading(true)
      getPhysicalAlbum(storedId, userId).then((response) => {
        if (!response.error) {
          if (response.body.length === 0) setNoFiles(true)
          setResponseData(response.body)
        }
        setLoading(false)
      })
    }
  }, [currentPage])

  useEffect(() => {
    const handleScroll = () => {
      if (
        containerRef.current &&
        containerRef.current.scrollHeight - containerRef.current.scrollTop ===
          containerRef.current.clientHeight &&
        !loading
      ) {
        setCurrentPage((prevPage) => prevPage + 1)
      }
    }

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll)
      }
    }
  }, [loading])

  // const removeFromPhysical = () => {
  //   const storedKey = localStorage.getItem("key")
  //   const userId = JSON.parse(storedKey).body.id
  //   const body = {
  //     userId: userId,
  //     fileIds: selectedImageIds,
  //     addToPhysicalAlbum: false,
  //   }
  //   removeFromPhysicalAlbum(body).then((response) => {
  //     if (!response.error) {
  //       let modifyData = responseData.filter(
  //         (item) => !selectedImageIds.includes(item.id)
  //       )
  //       setResponseData(modifyData)
  //       setSelectedItems([])
  //       setSelectedImageIds([])
  //     }
  //   })
  // }

  const removeFromPhysical = async () => {
    const storedKey = localStorage.getItem("key")
    const userId = JSON.parse(storedKey).body.id

    const body = {
      userId: userId,
      fileIds: selectedImageIds,
      addToPhysicalAlbum: false,
    }

    if (!selectedImageIds.length) {
      console.error("No images selected for removal.")
      return
    }

    try {
      const response = await moveToPhysicalAlbum(body)

      if (!response.error) {
        const updatedData = responseData.filter(
          (item) => !selectedImageIds.includes(item.id)
        )
        if (updatedData?.length <= 0) {
          setNoFiles(true)
        }
        setResponseData(updatedData)
        setSelectedItems([])
        setSelectedImageIds([])
        toast.success("File removed from physical album successfully")
      } else {
        console.error("Error in API response:", response.error)
      }
    } catch (error) {
      console.error("Error in removing images:", error)
    }
  }

  const handleImageIds = (id) => {
    const isPresent = selectedImageIds?.find((item) => item === id)
    if (isPresent)
      setSelectedImageIds((prev) => prev?.filter((item) => item !== id))
    else setSelectedImageIds((prev) => [...prev, id])
  }

  const handleDownload = () => {
    if (selectedItems.length > 0) {
      const zip = new JSZip()
      let imagesProcessed = 0

      selectedItems.forEach((selectedIndex) => {
        const url = responseData[selectedIndex]?.url
        const name = responseData[selectedIndex]?.name
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            zip.file(name, blob)
            imagesProcessed++

            if (imagesProcessed === selectedItems.length) {
              zip.generateAsync({ type: "blob" }).then((content) => {
                const anchor = document.createElement("a")
                anchor.href = URL.createObjectURL(content)
                anchor.download = "physical_album_images.zip"
                anchor.click()
              })
            }
          })
          .catch((error) => {
            console.error("Error downloading image:", error)
            imagesProcessed++
          })
      })
    }
  }

  const handleSelectAll = (event) => {
    setSelectAllChecked(event.target.checked)
    if (event.target.checked) {
      const allIndices = responseData.map((_, index) => index)
      setSelectedItems(allIndices)
    } else {
      setSelectedItems([])
    }
  }

  const handleSelect = (index, event) => {
    const imageId = responseData[index].id

    if (isShiftPressed && lastSelectedIndex !== null) {
      const start = Math.min(lastSelectedIndex, index)
      const end = Math.max(lastSelectedIndex, index)

      const rangeSelectedItems = Array.from(
        { length: end - start + 1 },
        (_, i) => start + i
      )

      setSelectedItems((prevSelectedItems) => {
        const newSelection = new Set([
          ...prevSelectedItems,
          ...rangeSelectedItems,
        ])
        return Array.from(newSelection)
      })

      const rangeImageIds = responseData
        .slice(start, end + 1)
        .map((item) => item.id)
      setSelectedImageIds((prev) => [...new Set([...prev, ...rangeImageIds])])
    } else {
      setSelectedItems((prevSelectedItems) => {
        if (prevSelectedItems.includes(index)) {
          return prevSelectedItems.filter((item) => item !== index)
        } else {
          return [...prevSelectedItems, index]
        }
      })

      if (selectedImageIds.includes(imageId)) {
        setSelectedImageIds((prev) => prev.filter((id) => id !== imageId))
      } else {
        setSelectedImageIds((prev) => [...prev, imageId])
      }
    }

    if (!isShiftPressed) {
      setLastSelectedIndex(index)
    }
  }

  const handleUpload = async () => {
    fileInputRef.current.click()
  }

  const handleFileChange = (event) => {
    let selectedFiles = event.target.files
    let selectedFilesLength = selectedFiles.length
    let uploadCount = 0
    let uploadPercent = 0
    if (!selectedFiles) {
      setUploadMessage("Please select files to upload.")
      return
    }
    const formData = new FormData()
    const storedObjectString = localStorage.getItem("key")
    const storedObject = JSON.parse(storedObjectString)
    const storedEventId = localStorage.getItem("eventId")
    let params = {
      userId: storedObject.body.id,
      eventId: storedEventId,
    }
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.delete("files")
      formData.append("files", selectedFiles[i])

      uploadFiles(params, formData)
        .then((response) => {
          if (!response.error) {
            setUploadMessage("")
            uploadCount++
            uploadPercent = Math.round(
              (uploadCount * 100) / selectedFilesLength
            )
            if (uploadPercent === 100) {
              setTimeout(() => {
                window.location.reload()
              }, 10000)
            }
          } else {
            setUploadMessage("Upload failed for some files.")
          }
        })
        .catch(() => {
          setUploadMessage("Error uploading files. Please try again.")
        })
    }
  }

  useEffect(() => {
    const detectKeyDown = (e) => {
      if (e.key === "Shift") {
        setIsShiftPressed(true)
      }
    }

    const detectKeyUp = (e) => {
      if (e.key === "Shift") {
        setIsShiftPressed(false)
      }
    }

    document.addEventListener("keydown", detectKeyDown, true)
    document.addEventListener("keyup", detectKeyUp, true)

    return () => {
      document.removeEventListener("keydown", detectKeyDown, true)
      document.removeEventListener("keyup", detectKeyUp, true)
    }
  }, [])

  // handle height from top
  useEffect(() => {
    const storageElement = document.getElementById("physical-album-event")
    if (storageElement) {
      setFromTop(storageElement.getBoundingClientRect().top)
    } else {
      console.error("Element with ID 'album-access' not found.")
    }
  }, [])

  const selectAllImages = () => {
    const allImageIds = responseData.map((item) => item.id)
    setSelectedImageIds(allImageIds)
    const allIndexes = responseData.map((_, index) => index)
    setSelectedItems(allIndexes)
  }

  const deselectAllImages = () => {
    setSelectedImageIds([])
    setSelectedItems([])
  }

  useEffect(() => {
    console.log("Selected Items updated:", selectedItems)
  }, [selectedItems])

  const anyImagesSelected = selectedItems.length > 0

  return (
    <div id="physical-album-event">
      <div
        className="d-flex justify-content-between align-items-center bg-black"
        style={{ borderRadius: "20px", padding: "23px 32px", margin: "10px" }}
      >
        <div className="d-flex align-items-center">
          {anyImagesSelected && (
            <button
              onClick={selectAllImages}
              type="button"
              style={{
                minWidth: "8rem",
                background: "linear-gradient(90deg, #F6C67C 0%, #A58447 100%)",
                borderRadius: "25px",
                color: "#000",
                outline: "none",
                border: "none",
                marginLeft: "10px",
                padding: "10px 53px",
                fontFamily: "Roboto",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "21.09px",
                textAlign: "right",
              }}
            >
              Select All
            </button>
          )}

          {anyImagesSelected && (
            <button
              onClick={deselectAllImages}
              type="button"
              style={{
                border: "none",
                background: "none",
              }}
            >
              <Tooltip title="Deselect All Images">
                <IconButton>
                  <CancelIcon className="text-white" />
                </IconButton>
              </Tooltip>
            </button>
          )}
        </div>

        <div>
          {uploadMessage && <span className="pe-2">{uploadMessage}</span>}
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "15px",
            padding: "0 16px",
            alignItems: "center",
          }}
        >
          {anyImagesSelected ? (
            <>
              <Tooltip title="Download" placement="bottom">
                <IconButton onClick={handleDownload}>
                  <img src={downloadActive} alt="Download Active" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remove from Physical Album" placement="bottom">
                <IconButton onClick={removeFromPhysical}>
                  <img
                    src={deleteAlbumActive}
                    alt="Remove from Physical Album"
                  />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Download" placement="bottom">
                <IconButton onClick={handleDownload}>
                  <img src={download} alt="Download" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remove from Physical Album" placement="bottom">
                <IconButton onClick={removeFromPhysical}>
                  <img src={deleteAlbum} alt="Remove from Physical Album" />
                </IconButton>
              </Tooltip>
            </>
          )}

          <FileCounter count={responseData?.length} />
        </div>

        {/* <div className="d-flex align-items-center">
          <span className="select-all-text me-3">Select All</span>
          <Checkbox
            label="LAB"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            className="select-check"
            checked={selectAllChecked}
            onChange={handleSelectAll}
          />
        </div>
        {selectedItems.length != 0 && (
          <div className="d-flex align-items-center">
            <span className="gold-text">{selectedItems.length} &nbsp;</span>
            <span className="white-text">
              Photos Selected for download &nbsp;
            </span>
            <Download className="action-button" onClick={handleDownload} />
          </div>
        )} */}
      </div>

      <div
        className="d-flex flex-wrap"
        style={{
          borderRadius: "20px",
          minHeight: `calc(100vh - ${fromTop}px - 110px)`,
          background: "#000",
          margin: "10px",
        }}
        ref={containerRef}
      >
        {responseData?.length > 0 ? (
          responseData.map((item, index) => (
            <div key={index} className="col-3 photo-card p-2">
              <div className="image-container" style={{ position: "relative" }}>
                <Checkbox
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    zIndex: 1,
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                  }}
                  className="select-check"
                  onChange={() => handleSelect(index)}
                  checked={selectedItems.includes(index)}
                />
                <img
                  className={`cover-photo`}
                  src={item.compressedUrl ? item.compressedUrl : item.url}
                  alt="item.url"
                  data-url={item.url}
                  onClick={() => handleSelect(index)}
                />
              </div>
            </div>
          ))
        ) : noFiles ? (
          <div
            style={{
              color: "white",
              textAlign: "center",
              margin: "50px",
              fontSize: "22px",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No images selected for physical album :(
          </div>
        ) : (
          Array.from({ length: 8 }, (_, index) => index + 1).map(() => (
            <div className="col-3 photo-card mb-3 p-2">
              <div className="image-container" style={{ position: "relative" }}>
                <div
                  className="shimmer"
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "20px",
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background:
                      "linear-gradient(90deg, #f0f0f0b7 0%, #e0e0e093 20%, #f0f0f08e 40%, #f0f0f091 100%)",
                  }}
                />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default PhysicalAlbum
