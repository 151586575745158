
const baseUrl = process.env.REACT_APP_API_BASE_URL

export const apiURL = {
  username: process.env.REACT_APP_API_USERNAME,
  password: process.env.REACT_APP_API_PASSWORD,

  loginSendOTP: `${baseUrl}/auth/verifyPhone/sendOtp`,
  authLoginOTP: `${baseUrl}/auth/verifyPhone/compareOtp`,
  getEventsAbout: `${baseUrl}/events/`,
  getQRCodeOfEvent: `${baseUrl}/events/qr/`,
  getReferredEvents: `${baseUrl}/events/referred?userId=`,
  getAllEvents: `${baseUrl}/events/photographer?userId=`,
  getAllFilesOfEvents: `${baseUrl}/file/list/v2?eventId=`,
  uploadFiles: `${baseUrl}/events/upload/v2?`,
  getPrivacyPolicy: `${baseUrl}/support/privacy`,
  getTermsAndConditions: `${baseUrl}/support/tnc`,
  getRefundPolicy: `${baseUrl}/support/refund`,
  getCancellationPolicy: `${baseUrl}/support/cancellation`,
  deleteFile: `${baseUrl}/file/delete?userId=`,
  likeOrUnlikeFile: `${baseUrl}/file/like?userId=`,
  getPhysicalAlbum: `${baseUrl}/file/physical-album?eventId=`,
  contact: `${baseUrl}/support/contact`,
  deleteFileList: `${baseUrl}/file/delete/list`,
  createFolder: `${baseUrl}/folder/create`,
  getFoldersOfEvent: `${baseUrl}/folder/list?eventId=`,
  updateFolderCoverPhoto: `${baseUrl}/folder/cover/photo/`,
  deleteFolder: `${baseUrl}/folder/delete`,
  editFolder: `${baseUrl}/folder/edit`,
  getAllFilesOfFolder: `${baseUrl}/file/folder/list?userId=`,
  moveFiles: `${baseUrl}/file/move`,
  getSubscriptionDetails: `${baseUrl}/subscription/details?userId=`,
  createOrder: `${baseUrl}/payment/create/order`,
  confirmPayment: `${baseUrl}/payment/verify`,
  createEvent: `${baseUrl}/events/create/v2`,
  uploadCoverPhoto: `${baseUrl}/events/cover/photo`,
  getPaymentModes: `${baseUrl}/payment/slabs?deviceType=webapp`,
  createMobileEvent: `${baseUrl}/events/create/v2`,
  getAllPublicFilesOfEvent: `${baseUrl}/file/all?eventId=`,
  getGuestUploads: `${baseUrl}/file/guest/uploads?eventId=`,
  getUserDetails: `${baseUrl}/user/`,
  deleteAccount: `${baseUrl}/user/delete?userId=`,
  getReferralId: `${baseUrl}/payment/referrals/`,
  createReferralId: `${baseUrl}/payment/referral/`,
  getDashboard: `${baseUrl}/dashboard/`,
  getEventGuests: `${baseUrl}/events/guests/`,
  getUserByPhoneNumber: `${baseUrl}/user/phone/`,
  editUserRoleForEvent: `${baseUrl}/events/role?`,
  joinEvent: `${baseUrl}/events/join?`,
  removeUserFromEvent: `${baseUrl}/events/remove?`,
  toggleFaceRecognition: `${baseUrl}/events/faceprivacy?`,
  toggleGuestUpload: `${baseUrl}/events/guestupload?`,
  deleteEvent: `${baseUrl}/events/`,
  editEventDetails: `${baseUrl}/events/edit`,
  userRoleInEvent: `${baseUrl}/user/role?userId=`,
  moveToPhysicalAlbum: `${baseUrl}/file/update/physical-album`,
  removeFromPhysicalAlbum: `${baseUrl}/file/update/physical-album`,
  getSubscriptionEventConfig: `${baseUrl}/gallery/config?eventId=`,
  setPincode: `${baseUrl}/gallery/pin`,
  deleteNotification: `${baseUrl}/notifications/clear/?id=`,
  createProfile: `${baseUrl}/user/createProfile`,
  uploadProfilePhoto: `${baseUrl}/user/profile/photo/`,
  downloadEventData: `${baseUrl}/events/data/`,
  clearNotifications: `${baseUrl}/notifications/clear/all/?userId=`
}
