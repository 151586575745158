import React, { useState, useEffect } from "react"
import "./Event.scss"
import { useNavigate, useLocation } from "react-router-dom"
import EventNav from "../EventNav/EventNav"
import Header from "../Header/Header"
import About from "./About/About"
import AllFiles from "./AllFiles/AllFiles"
import PhysicalAlbum from "./PhysicalAlbum/PhysicalAlbum"
import AvailableSoon from "../AvailableSoon/AvailableSoon"
import Finish from "../Pagination/Finish/Finish"
import AlbumAccess from "./AlbumAccess/AlbumAccess"
import EventSettings from "./EventSettings/EventSettings"

const Event = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [pageIndex, setPageIndex] = useState(1)
  const [rootLocation, setRootLocation] = useState(true)
  const [mainData, setMainData] = useState({})

  const [isEventCreated, setIsEventCreated] = useState()
  const [insideGalleryFolder, setInsideGalleryFolder] = useState("")
  // const [isEventUpdated, setIsEventUpdated] = useState()

  const pageKeys = {
    1: "About Event",
    2: "Gallery",
    3: "Physical Album",
    4: "Folders",
    5: "Album Access",
    6: "Event Settings",
  }

  useEffect(() => {
    const currentPath = location.pathname
    switch (currentPath) {
      case "/event":
      case "/event/about":
        setPageIndex(1)
        break

      case "/event/all":
        setPageIndex(2)
        break

      case "/event/physical":
        setPageIndex(3)
        break

      case "/event/folder":
        setPageIndex(4)
        break

      case "/event/album-access":
        setPageIndex(5)
        break

      case "/event/album-settings":
        setPageIndex(6)
        break

      default:
        break
    }
  }, [location.pathname])

  useEffect(() => {
    const setIsEventCreatedData = () => {
      const res = JSON.parse(sessionStorage?.getItem("eventCreated"))
      if (res) {
        setIsEventCreated(true)
      } else {
        setIsEventCreated(false)
      }
    }
    setIsEventCreatedData()
  }, [])

  useEffect(() => {
    const isEventSelected = () => {
      const res = localStorage.getItem("eventId")
      if (!res) navigate("/home/all?page=0&limit=10")
    }
    isEventSelected()
  }, [])

  useEffect(() => {
    const removeSessionKey = () => {
      if (!isEventCreated) return
      sessionStorage.removeItem("eventCreated")
    }
    removeSessionKey()
  }, [isEventCreated])

  useEffect(() => {
    const storedObjectString = localStorage.getItem("key")
    if (storedObjectString) {
      const storedObject = JSON.parse(storedObjectString)
      setMainData(storedObject.body)
    }
  }, [])

  const pageName = (data) => {
    setPageIndex(data)
  }

  return (
    <div className="box d-flex">
      {isEventCreated && <Finish setIsEventCreated={setIsEventCreated} />}
      <div className="navBar">
        <EventNav pageInfo={pageName} setInsideGalleryFolder={setInsideGalleryFolder}
                  backFromGalleryFolder={setRootLocation} />
      </div>
      <div className="header-box">
        <Header
          page={pageKeys[pageIndex]}
          event="upload"
          backFromGalleryFolder={setRootLocation}
          isInsideGalleryFolder={rootLocation}
          insideGalleryFolder={insideGalleryFolder}
          setInsideGalleryFolder={setInsideGalleryFolder}
        />
        {pageIndex === 1 && <About />}
        {pageIndex === 2 && (
          <AllFiles
            rootLocation={rootLocation}
            setRootLocation={setRootLocation}
            setInsideGalleryFolder={setInsideGalleryFolder}
          />
        )}
        {pageIndex === 3 && <PhysicalAlbum />}
        {pageIndex === 4 && <AvailableSoon />}
        {pageIndex === 5 && <AlbumAccess />}
        {pageIndex === 6 && <EventSettings />}
      </div>
    </div>
  )
}

export default Event
